Mixins.objectGoogleMaps = {
    data() {
        return {
            map: null,
            bounds: null,
            clusterer: null,
            markers: [],
            infoWindow: null
        }
    },
    methods: {
        initializeMap() {
            this.bounds = new google.maps.LatLngBounds();
            this.infowindow = new google.maps.InfoWindow();

            this.map = new google.maps.Map(this.$refs.map, {
                center: {lat: 52.078663, lng: 5.288788},
                zoom: 7,
                //maxZoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                styles: [
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#000000"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#c0c5cd"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    }
                ]
            });
        },

        addMarkers(data) {
            data.forEach(m => {
                let marker = new google.maps.Marker({
                    position: new google.maps.LatLng(m.latitude, m.longitude),
                    map: this.map,
                    title: m.title,
                    icon: '//' + window.location.host + '/images/icons/pointer.svg'
                });

                google.maps.event.addListener(marker, 'click', () => {
                    this.infowindow.setContent(m.view);
                    this.infowindow.open(this.map, marker);
                });

                //extend the bounds to include each marker's position
                this.bounds.extend(marker.position);
                this.markers.push(marker);
            });

            // now fit the map to the newly inclusive bounds
            if (this.markers.length > 0) {
                this.map.fitBounds(this.bounds);
            } else {
                this.map.setZoom(5);
            }

            // Add a marker clusterer to manage the markers.
            this.clusterer = new MarkerClusterer(this.map, this.markers, {
                imagePath: '//' + window.location.host + '/images/maps/m'
            });
        }
    }
};
