Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-edit-step-5')) {
        ManageProperties.components.editStep5 = new Vue({
            el: document.getElementById("property-edit-step-5"),
            store,
            data: function () {
                return {
                    rooms: [],
                    imagesFloorplans: [],
                    hasImages: true,
                    roomTypes: ['bathroom', 'bedroom', 'kitchen', 'livingroom', 'toilet'],
                    bathroomCounts: 1,
                    bedroomCounts: 1,
                    kitchenCounts: 1,
                    livingroomCounts: 1,
                    toiletCounts: 1,
                    initialized: false,
                    kitchenOptions: []
                }
            },
            mixins: [Mixins.submitStep, Mixins.propertyJSON, Mixins.uploadImages, Mixins.deleteImage, Mixins.cleanImages, Mixins.addImages],
            computed: {
                roomsToSend() {
                    let clonedRooms = JSON.parse(JSON.stringify(this.rooms));

                    clonedRooms.forEach((room, roomIndex) => {
                        room.images.forEach((image, imageIndex) => {
                            image['blob'] = null;
                        });
                    });

                    return JSON.stringify(clonedRooms);
                },
                bedrooms() {
                    return this.rooms.filter(room => room.type === 'bedroom');
                },
                toilets() {
                    return this.rooms.filter(room => room.type === 'toilet');
                },
                bathrooms() {
                    return this.rooms.filter(room => room.type === 'bathroom');
                },
                kitchens() {
                    return this.rooms.filter(room => room.type === 'kitchen');
                },
                livingrooms() {
                    return this.rooms.filter(room => room.type === 'livingroom');
                },
                filteredRooms() {
                    return this.rooms.filter(
                        room => room.description !== '' || room.images.length
                    );
                }
            },
            watch: {
                rooms: {
                    handler() {
                        this.$refs.rooms.value = JSON.stringify(this.filteredRooms);
                    },
                    deep: true
                },
                imagesFloorplans: {
                    handler(newValue) {
                        this.$refs.imagesFloorplans.value = JSON.stringify(newValue);
                    },
                    deep: true
                },
                bathroomCounts(newValue, oldValue) {
                    if (this.initialized) {
                        this.updateRooms('bathroom', newValue, oldValue);
                    }
                },
                bedroomCounts(newValue, oldValue) {
                    if (this.initialized) {
                        this.updateRooms('bedroom', newValue, oldValue);
                    }
                },
                kitchenCounts(newValue, oldValue) {
                    if (this.initialized) {
                        this.updateRooms('kitchen', newValue, oldValue);
                    }
                },
                kitchenOptions(newValue) {
                    if (this.initialized) {
                        Vue.set(this.kitchens[0], 'options', newValue);
                    }
                },
                toiletCounts(newValue, oldValue) {
                    if (this.initialized) {
                        this.updateRooms('toilet', newValue, oldValue);
                    }
                },
                livingroomCounts(newValue, oldValue) {
                    if (this.initialized) {
                        this.updateRooms('livingroom', newValue, oldValue);
                    }
                }
            },
            mounted() {
                const that = this;
                this.rooms = JSON.parse(this.$refs.rooms.value);

                this.bathroomCounts = this.bathrooms.length ? this.bathrooms.length : 1;
                this.bedroomCounts = this.bedrooms.length ? this.bedrooms.length : 1;
                this.kitchenCounts = this.kitchens.length ? this.kitchens.length : 1;
                this.toiletCounts = this.toilets.length ? this.toilets.length : 1;
                this.livingroomCounts = this.livingrooms.length ? this.livingrooms.length : 1;
                this.kitchenOptions = this.kitchens.length ? this.kitchens[0]['options'] : [];

                this.verifyRooms();
                this.setFloorplansImages();

                window.setTimeout(function () {
                    that.initialized = true;
                }, 0);
            },
            methods: {
                setFloorplansImages() {
                    this.imagesFloorplans = JSON.parse(this.$refs.imagesFloorplans.value);
                },
                verifyRooms() {
                    const that = this;
                    this.roomTypes.forEach(type => {
                        if (!this.rooms.filter(room => room.type === type).length) {
                            that.addEmptyRoom(type);
                        }
                    })
                },
                addEmptyRoom(type) {
                    this.rooms.push({
                        id: null,
                        type: type,
                        description: '',
                        description_de: '',
                        images: []
                    });
                },
                removeRooms(type, amount) {
                    let removed = 0;
                    let reversedRooms = this.rooms.slice().reverse();

                    for (let i = 1; i <= amount; i++) {
                        reversedRooms.forEach((room, index) => {
                            if (room.type === type && removed < i) {
                                reversedRooms.splice(index, 1);
                                removed++;
                            }
                        });
                    }

                    this.rooms = reversedRooms.reverse();
                },
                updateRooms(type, newValue, oldValue) {
                    if (newValue > oldValue) {
                        let amount = newValue - oldValue;

                        for (let i = 0; i < amount; i++) {
                            this.addEmptyRoom(type);
                        }
                    } else if (newValue < oldValue) {
                        let amount = oldValue - newValue;
                        this.removeRooms(type, amount);
                    }
                },
                uploadRoomImages(type, room, e, limit) {
                    const that = this;

                    this.uploadImages(e, room.images.length, limit).then(images => {
                        images.forEach(image => {
                            let roomImage = {
                                path: image.path,
                                blob: image.blob,
                                name: image.name,
                                id: null
                            };

                            room.images.push(roomImage);
                        });
                    }).catch(error => {
                        // that.errorText = error;
                    });
                },
                deleteRoomImage(image, list, type, roomIndex) {
                    list[roomIndex].images.splice(list[roomIndex].images.indexOf(image), 1);
                    document.getElementById(type.type + '-images-' + roomIndex).value = null;
                },
                customDeleteImage(images, image, inputID) {
                    this.deleteImage(images, image, inputID);
                    this.setImagesToSend();
                },
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
