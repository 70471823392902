Layout.store.mutations = {
    setMenuExpanded(state, value) {
        state.menuExpanded = value;
    },
    setSearchExpanded(state, value) {
        state.searchExpanded = value;
    },
    setErrorText(state, value) {
        state.errorText = value;
    },
    setMessageText(state, value) {
        state.messageText = value;
    },
};