Mixins.getProperty = {
    data: function () {
        return {
            property: null
        }
    },
    methods: {
        get() {
            let that = this;
            let data = new FormData(this.$el);

            this.$store.dispatch('ManageProperties/show', data.get('_id')).then(function (response) {
                that.property = response.data;
            });
        }
    },
    mounted: function () {
        if (! window.property || this.property === null) {
            this.get();
        }
    },
    watch: {
        property: function (newValue, oldValue) {
            this.property = newValue;
            window.property = newValue;
        }
    }
};