Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('search-banner')) {
        Layout.components.searchBanner = new Vue({
            el: document.getElementById("search-banner"),
            store,
            data: function () {
                return {
                    selectedCountry: 1,
                }
            },
            computed: {
                searchExpanded: {
                    get() {
                        return this.$store.state.Layout.searchExpanded;
                    },
                    set(value) {
                        this.$store.dispatch('Layout/changeSearchExpanded', value)
                    }
                }
            },
            mounted: function () {
                this.selectedCountry = parseInt(this.$refs.countryField.value);
            },
            methods: {
                submit() {
                    this.$refs.searchBannerForm.submit();
                },
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

