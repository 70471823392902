Mixins.mousePosition = {
    methods: {
        getMousePositionToDocument(e) {
            let posx = 0;
            let posy = 0;

            if (!e) {
                let e = window.event;
            }

            if (e.pageX || e.pageY) {
                posx = e.pageX;
                posy = e.pageY;
            }
            else if (e.clientX || e.clientY) {
                posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
            } else if (e.touches[0].pageX || e.touches[0].pageY) {
                posx = e.touches[0].pageX;
                posy = e.touches[0].pageY;
            }

            return {
                x: posx,
                y: posy
            };
        },
        getMousePositionToElement(e, target) {
            const mousePosDoc = this.getMousePositionToDocument(e);
            const targetPos = this.findPos(target);

            const posx = mousePosDoc.x - targetPos.left;
            const posy = mousePosDoc.y - targetPos.top;

            return {
                x: posx,
                y: posy
            };
        },
        findPos: function (obj) {
            let curleft = 0;
            let curtop = 0;

            if (obj.offsetParent) {
                do {
                    curleft += obj.offsetLeft;
                    curtop += obj.offsetTop;
                } while (obj = obj.offsetParent);
            }

            return {
                left: curleft,
                top: curtop
            };
        },
    }
};
