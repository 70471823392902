Mixins.checkUsername = {
    created: function () {
        const that = this;

        VeeValidate.Validator.extend('check_username', {
            getMessage(field, params, data) {
                return (data && data.message) || 'Something went wrong';
            },
            validate(value) {
                return new Promise(resolve => {
                    that.$store.dispatch('ManageAccount/check', {email: value}).then(response => {
                        resolve(response.data);
                    });
                });
            }
        });
    }
};