Mixins.submitStep = {
    data: function() {
        return {
            MoveToInstance: new MoveTo({
                tolerance: 100,
                duration: 800
            }),
        }
    },
    computed: {
        messageText: {
            get: function() {
                return this.$store.state.Layout.messageText;
            },
            set: function(value) {
                this.$store.dispatch('Layout/changeMessageText', value)
            }
        },
        errorText: {
            get: function() {
                return this.$store.state.Layout.errorText;
            },
            set: function(value) {
                this.$store.dispatch('Layout/changeErrorText', value)
            }
        }
    },
    methods: {
        submit: function() {
            let that = this;
            this.$validator.validateAll().then(function (valid) {
                if (! valid) {
                    that.MoveToInstance.move(document.querySelector('.input--invalid').parentElement);
                    return;
                }

                let formData = new FormData(that.$el);
                that.$el.classList.add('form--loading');

                return axios({
                    method: 'POST',
                    url: '//' + window.location.host + '/api/user/properties/' + formData.get('_id'),
                    data: formData
                }).then(function (response) {
                    that.messageText = response.data.message;
                    if (response.data.target) {
                        Barba.Pjax.goTo(response.data.target);
                    } else {
                        that.$el.classList.remove('form--loading');
                    }

                    if(response.data.dataLayer) {
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push(response.data.dataLayer);
                    }

                }).catch(function (error) {
                    that.$el.classList.remove('form--loading');
                    if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
                        let message = '';
                        for (let field in error.response.data.errors) {
                            message += '<br>' + error.response.data.errors[field];
                        }

                        that.errorText = error.response.data.message + message;
                    } else if (error.hasOwnProperty('response') && error.response.hasOwnProperty('message')) {
                        that.errorText = error.response.data.message;
                    }
                });
            });
        }
    }
};
