Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-edit-step-10')) {
        ManageProperties.components.editStep10 = new Vue({
            el: document.getElementById("property-edit-step-10"),
            store,
            data: function () {
                return {
                    hasRentalPossibility: 0
                }
            },
            mixins: [Mixins.submitStep, Mixins.propertyJSON],
            computed: {
                rentalPossibilityRuleRequired() {
                    return this.property.rental_possibility ? 'required' : '';
                },

                rentalPossibilityRuleRequiredPrice() {
                    return (this.property.rental_possibility ? 'required|' : '') + this.rentalPossibilityRulePrice;
                },

                rentalPossibilityRulePrice() {
                    return 'min_value:0|max_value:100000';
                },

                costs() {
                    // alle kosten bij elkaar optellen
                    let result = 0;
                    if (parseFloat(this.property.price_park)) result += parseFloat(this.property.price_park);
                    if (parseFloat(this.property.price_land_rent)) result += parseFloat(this.property.price_land_rent);
                    if (parseFloat(this.property.price_annual_lease)) result += parseFloat(this.property.price_annual_lease);
                    if (parseFloat(this.property.rental_costs)) result += parseFloat(this.property.rental_costs);
                    return result;
                },

                incomes() {
                    // alle inkomsten bij elkaar optellen
                    let year1 = parseFloat(this.property.rental_income_year1);
                    let year2 = parseFloat(this.property.rental_income_year2);

                    if (year2 < 1) {
                        return year1;
                    }

                    // berekening gaat per jaar, dus bij 2 jaar / 2
                    return ((year1 + year2) / 2);
                },

                profitability() {
                    // rendabiliteit = (Huurinkomsten - (basiskosten + externe kosten) / vraagprijs) * 100
                    let result = parseFloat(((this.incomes - this.costs) / parseFloat(this.property.price)) * 100);

                    if (isNaN(result)) {
                        return 0;
                    }

                    return result;
                },

                profitabilityFormatted() {
                    // netjes afronden en formatten naar een %
                    let result = (Math.round(this.profitability * 100) / 100);

                    if (isNaN(result)) {
                        return 0 + '%'
                    }

                    return result + '%';
                }
            },
            mounted: function () {
                this.hasRentalPossibility = this.property.rental_possibility;
            },
            methods: {
                toggleRentalPossibility(e) {
                    this.hasRentalPossibility = (parseInt(e.target.value) > 0);
                }
            },
            created: function () {
                this.hasRentalPossibility = this.property.rental_possibility;
            },
            destroyed: function () {
            }
        });
    }
});