Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-edit-step-3')) {
        ManageProperties.components.editStep3 = new Vue({
            el: document.getElementById("property-edit-step-3"),
            store,
            data: function () {
                return {
                    description: '',
                    description_de: '',
                    title: '',
                    title_de: '',
                    images: [],
                    imagesToSend: [],
                }
            },
            mixins: [Mixins.submitStep, Mixins.propertyJSON, Mixins.uploadImages, Mixins.deleteImage, Mixins.cleanImages, Mixins.addImages],
            watch: {
                images: {
                    handler(newValue) {
                        this.$refs.images.value = JSON.stringify(newValue);
                        this.setImagesToSend();
                        this.setDragEvents();
                    },
                    deep: true
                },
            },
            mounted: function () {
                this.setImages();
                this.setDescription();
                this.setTitle();
                this.setImagesToSend();
            },
            methods: {
                setImages() {
                    this.images = JSON.parse(this.$refs.images.value);
                    this.setDragEvents();
                },
                setDescription() {
                    this.description = this.property.description;
                    this.description_de = this.property.description_de;
                },
                setTitle() {
                    this.title = this.property.title;
                    this.title_de = this.property.title_de;
                },
                setDragEvents() {
                    setTimeout(function() {
                        window.attachDragEvents();
                    }, 1000);
                },
                setImagesToSend(newValue) {
                    let clonedImages = JSON.parse(JSON.stringify(this.images));

                    clonedImages.forEach((image, imageIndex) => {
                        image['blob'] = null;
                    });

                    if(newValue) {
                        clonedImages = newValue;
                    }

                    let correctImages = JSON.stringify(clonedImages);
                    this.imagesToSend = correctImages;
                },
                customDeleteImage(images, image, inputID) {
                    this.deleteImage(images, image, inputID);
                    this.setImagesToSend();
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
