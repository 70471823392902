Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-create')) {
        ManageProperties.components.create = new Vue({
            el: document.getElementById("property-create"),
            store,
            data: function () {
                return {
                    result: []
                }
            },
            mixins: [Mixins.addressLookup],
            computed: {
                messageText: {
                    get() {
                        return this.$store.state.Layout.messageText;
                    },
                    set(value) {
                        this.$store.dispatch('Layout/changeMessageText', value)
                    }
                },
            },
            mounted: function () {
            },
            methods: {
                submit() {
                    let that = this;
                    this.$validator.validateAll().then(result => {
                        if (result) {
                            let loader = setTimeout(function () {
                                that.$el.classList.add('form--loading');
                            }, 500);

                            this.$store.dispatch('ManageProperties/store', new FormData(this.$el)).then(function(response) {
                                clearInterval(loader);
                                that.messageText = response.data.message;

                                if (response.data.target) {
                                    Barba.Pjax.goTo(response.data.target);
                                } else {
                                    that.$el.classList.remove('form--loading');
                                }
                            }).catch(function (error) {
                                clearInterval(loader);

                                that.$el.classList.remove('form--loading');
                                if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
                                    let message = '';
                                    for (let field in error.response.data.errors) {
                                        message += '<br>' + error.response.data.errors[field];
                                    }

                                    that.errorText = error.response.data.message + message;
                                }
                            });
                        } else {
                            let errorParent = document.querySelector('.form__invalid-message').parentElement;
                            that.MoveToInstance.move(errorParent);

                            setTimeout(function () {
                                errorParent.querySelector('[name]').focus();
                            }, 1000);
                        }
                    });
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
