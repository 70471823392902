if (document.getElementById('message')) {
    Layout.components.message = new Vue({
        el: document.getElementById("message"),
        store,
        data: function () {
            return {
                showMessage: false
            }
        },
        computed: {
            messageText: {
                get() {
                    return this.$store.state.Layout.messageText;
                },
                set(value) {
                    this.$store.dispatch('Layout/changeMessageText', value)
                }
            }
        },
        watch: {
            messageText(val) {
                const that = this;
                if (val !== null) {
                    that.showMessage = true;
                }
            },
            showMessage() {
                const that = this;

                window.setTimeout(function () {
                    that.showMessage = false;

                    window.setTimeout(function () {
                        if (!that.showMessage) {
                            that.messageText = null;
                        }
                    }, 400);
                }, 3000);
            }
        },
        mounted: function () {
        },
        methods: {
        },
        created: function () {
        },
        destroyed: function () {
        }
    });
}
