Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('images-loading')) {
        ManageProperties.components.imagesLoading = new Vue({
            el: document.getElementById("images-loading"),
            store,
            data: function () {
                return {
                }
            },
            computed: {
                imagesLoading: {
                    get() {
                        return this.$store.state.Properties.imagesLoading;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/toggleImagesLoading', value)
                    }
                },
            },
            mounted: function () {
            },
            methods: {
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});