Mixins.convertGeoToPixel = {
    methods : {
        convertGeoToPixel: function (latitude, longitude, clientWidth, clientHeight) {
            // X and Y boundaries
            const northLat = 53.555853;
            const southLat = 50.746063;

            const mapLonLeft = 3.359421;
            const mapLonRight = 7.220930;
            let mapLonDelta = mapLonRight - mapLonLeft;

            let mapLatBottom = 50.746063;
            let mapLatBottomDegree = mapLatBottom * Math.PI / 180;

            let x = (longitude - mapLonLeft) * (clientWidth / mapLonDelta);

            latitude = latitude * Math.PI / 180;
            let worldMapWidth = ((clientWidth / mapLonDelta) * 360) / (2 * Math.PI);
            let mapOffsetY = (worldMapWidth / 2 * Math.log((1 + Math.sin(mapLatBottomDegree)) / (1 - Math.sin(mapLatBottomDegree))));
            let y = clientHeight - ((worldMapWidth / 2 * Math.log((1 + Math.sin(latitude)) / (1 - Math.sin(latitude)))) - mapOffsetY);

            return {"x": x, "y": y};
        },
    }
};