Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-edit-step-11')) {
        ManageProperties.components.editStep11 = new Vue({
            el: document.getElementById("property-edit-step-11"),
            store,
            data: function () {
                return {
                    loading: false,
                    method: 'ideal'
                }
            },
            mixins: [Mixins.submitStep, Mixins.propertyJSON],
            computed: {},
            watch: {
                loading(value) {
                    if (value) {
                        this.$el.classList.add('form--loading');
                    } else {
                        this.$el.classList.remove('form--loading');
                    }
                }
            },
            mounted: function () {
            },
            methods: {
                submit() {
                    let that = this;
                    this.$validator.validateAll().then(result => {
                        if (result) {
                            this.loading = true;
                            this.$el.classList.add('form--loading');

                            this.$store.dispatch('ManageProperties/createPayment', new FormData(this.$el))
                                .then(function (response) {
                                    that.loading = false;
                                    that.$el.classList.remove('form--loading');

                                    if (response.data.externalTarget) {
                                        // harde redirect omdat het naar een externe pagina gaat.
                                        window.location.href = response.data.externalTarget;
                                    } else if (response.data.target) {
                                        Barba.Pjax.goTo(response.data.target);
                                    }
                                })
                                .catch(function (error) {
                                    that.loading = false;
                                    that.$el.classList.remove('form--loading');

                                    if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
                                        let message = '';
                                        for (let field in error.response.data.errors) {
                                            message += '<br>' + error.response.data.errors[field];
                                        }

                                        that.errorText = error.response.data.message + message;
                                    } else if (error.hasOwnProperty('response') && error.response.hasOwnProperty('message')) {
                                        that.errorText = error.response.data.message;
                                    }
                                });
                        }
                    });
                },
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});