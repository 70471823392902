Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('payment-status')) {
        ManageProperties.components.paymentStatus = new Vue({
            el: document.getElementById("payment-status"),
            store,
            data: function () {
                return {
                    status: 'open'
                }
            },
            computed: {},
            mounted: function () {
                this.check();
            },
            methods: {
                check: function () {
                    let that = this;

                    var propertyID = document.getElementById('payment-status').dataset.propertyId;

                    axios.get('/api/payment/status?property=' + propertyID).then(function (response) {

                        if(response.data.dataLayer) {
                            window.dataLayer = window.dataLayer || [];
                            dataLayer.push(response.data.dataLayer);
                        }

                        that.status = response.data.status;

                        if (that.status === 'open') {
                            setTimeout(function () {
                                that.check();
                            }, 1000);
                        }
                    });
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
