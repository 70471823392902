Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("hero")) {
        Global.components.hero = new Vue({
            el: document.getElementById("hero"),
            store,
            data: function () {
                return {
                }
            },
            computed: {},
            mounted: function () {
            },
            methods: {
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});