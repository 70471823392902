Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('chart')) {
        ManageProperties.components.create = new Vue({
            el: document.getElementById("chart"),
            store,
            data: function () {
                return {
                    showSlice: 0,
                }
            },
            mounted: function () {
                this.initLineChart();
                this.initPieChart();
            },
            methods: {
                initPieChart: function () {
                    let element = document.getElementById("pieChart");

                    new Chart(element, {
                        type: 'pie',
                        data: JSON.parse(element.dataset.sets),
                        options: {
                            responsive: true,
                            legend: {
                                display: true,
                                position: 'bottom',
                                fullWidth: false
                            },
                            tooltips: {
                                enabled: true,
                            }
                        }
                    });
                },
                initLineChart: function () {
                    let element = document.getElementById("lineChart");

                    new Chart(element, {
                        type: 'bar',
                        data: {
                            datasets: JSON.parse(element.dataset.sets)
                        },
                        options: {
                            responsive: true,
                            elements: {
                                line: {
                                    tension: 0.000001
                                }
                            },
                            legend: {
                                display: true,
                                position: 'top'
                            },
                            title: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                    type: "time",
                                    time: {
                                        unit: 'month',
                                        format: 'YYYY-MM',
                                        tooltipFormat: 'MMMM YYYY',
                                    },
                                    scaleLabel: {
                                        display: false,
                                        labelString: 'Date'
                                    }
                                }],
                                yAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'aantal'
                                    }
                                }]
                            }
                        }
                    });
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
