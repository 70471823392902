Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('home-hero')) {
        Global.components.homeHero = new Vue({
            el: document.getElementById("home-hero"),
            store,
            data: function () {
                return {
                    slider: null
                }
            },
            computed: {},
            mounted: function () {
                this.initializeSlider();
            },
            methods: {
                initializeSlider() {
                    this.slider = new Flickity(this.$refs.slider, {
                        prevNextButtons: false,
                        contain: true,
                        bgLazyLoad: 1
                    });
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});