ManageProperties.store.actions = {
    show({commit}, value) {
        return ManageProperties.api.show(value);
    },
    store({commit}, value) {
        return ManageProperties.api.store(value);
    },
    update({commit}, value) {
        return ManageProperties.api.update(value);
    },
    createPayment({commit}, value) {
        return ManageProperties.api.createPayment(value);
    }
};