Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-controls')) {
        Properties.components.propertyControls = new Vue({
            el: document.getElementById("property-controls"),
            store,
            data: function () {
                return {}
            },
            computed: {
                sort: {
                    get() {
                        return this.$store.state.Properties.sortOption;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/changeSortOption', value)
                    }
                },
                view: {
                    get() {
                        return this.$store.state.Properties.viewMode;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/changeViewMode', value)
                    }
                },
                showFilters: {
                    get() {
                        return this.$store.state.Properties.showFilters;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/showFilters', value)
                    }
                },
            },
            mounted: function () {
            },
            watch: {
            },
            methods: {
            },
        });
    }
});

