Mixins.submitForm = {
    data() {
        return {
            formMessage: '<div class="form-message form-message--active %status%">' +
            '   <div class="form-message__content">%message%</div>' +
            '   <span class="form-message__icon form-message__icon--success">' +
            '       <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z"/></svg>' +
            '   </span>' +
            '   <span class="form-message__icon form-message__icon--error">' +
            '       <svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1088 1248v224q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-224q0-26 19-45t45-19h256q26 0 45 19t19 45zm30-1056l-28 768q-1 26-20.5 45t-45.5 19h-256q-26 0-45.5-19t-20.5-45l-28-768q-1-26 17.5-45t44.5-19h320q26 0 44.5 19t17.5 45z"/></svg>' +
            '   </span>' +
            '</div>',
            MoveToInstance: new MoveTo({
                tolerance: 100,
                duration: 800
            }),
        }
    },
    methods: {
        submitForm(form, gtmEvent) {
            const that = this;

            form.classList.toggle('form--loading');

            if (form.querySelector('.form-message')) {
                form.querySelector('.form-message').remove();
            }

            let messageTemplate = this.formMessage;
            let formData = new FormData(form);

            axios({
                method: 'post',
                url: form.action,
                data: formData
            }).then(function (response) {
                if (response.data.externalTarget) {
                    // harde redirect omdat het naar een externe pagina gaat.
                    window.location.href = response.data.externalTarget;
                    return;
                } else if (response.data.target) {
                    Barba.Pjax.goTo(response.data.target);
                    return;
                }

                messageTemplate = messageTemplate.replace(/%message%/, response.data.message);
                messageTemplate = messageTemplate.replace(/%status%/, 'form-message--success');

                form.innerHTML = messageTemplate;
                form.classList.toggle('form--loading');

                form.parentElement.querySelector('.modal__title').innerHTML = response.data.modalTitle;
                form.parentElement.querySelector('.modal__text').innerHTML = '';

                // Sends the event to the Google Analytics property with
                // tracking ID GA_TRACKING_ID set by the config command in
                // the global tracking snippet.
                if (window.gtag !== undefined) {
                    gtag('event', 'Ingevuld', {
                        'event_category': (gtmEvent !== undefined ? gtmEvent : 'Formulierenbeheer')
                    });
                }

                this.MoveToInstance.move(document.querySelector('.form-message'));

            }).catch(function (error) {
                if (error.response !== undefined) {
                    messageTemplate = messageTemplate.replace(/%message%/, error.response.data.message);
                    messageTemplate = messageTemplate.replace(/%status%/, 'form-message--error');

                    form.classList.toggle('form--loading');

                    form.insertAdjacentHTML('afterbegin', messageTemplate);

                    that.MoveToInstance.move(document.querySelector('.form-message'));
                } else {

                    that.MoveToInstance.move(document.querySelector('.form-message'));
                }
            });
        }
    }
};
