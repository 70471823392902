Mixins.smoothScroll = {
    data() {
        return {
            MoveToInstance: new MoveTo({
                tolerance: 100,
                duration: 800
            }),
        }
    },
    mounted() {
        this.SmoothScroll();
    },
    methods: {
        SmoothScroll() {
            const that = this;
            const anchors = document.querySelectorAll('a[href^="#"]')
            this.setEventBind(anchors)

            setTimeout(function() {
                const anchors = document.querySelectorAll('a[href^="#"]')
                that.setEventBind(anchors)
            }, 1000)
        },
        setEventBind(anchors) {
            const that = this;

            anchors.forEach(anchor => {
                if (Math.floor(anchor.getAttribute('href').length) > 1) {
                    anchor.addEventListener('click', function (e) {
                        e.preventDefault();

                        let target = document.querySelector(this.getAttribute('href'));
                        that.MoveToInstance.move(target);
                    });
                }
            })
        }
    }
};
