Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-slider')) {
        Properties.components.propertySlider = new Vue({
            el: document.getElementById("property-slider"),
            store,
            mixins: [Mixins.manageFavorites],
            data: function () {
                return {
                    slider: null,
                    priceFormatter: new Intl.NumberFormat('nl-NL', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 0
                    }),
                }
            },
            computed: {},
            mounted: function () {
                this.initializeSlider();
            },
            methods: {
                findParentBySelector(elm, selector) {
                    const all = document.querySelectorAll(selector);
                    let cur = elm.parentNode;

                    while (cur && !this.collectionHas(all, cur)) { //keep going up until you find a match
                        cur = cur.parentNode; //go up
                    }
                    return cur; //will return null if not found
                },
                collectionHas(a, b) { //helper function (see below)
                    for (let i = 0, len = a.length; i < len; i++) {
                        if (a[i] === b) return true;
                    }
                    return false;
                },
                initializeSlider() {
                    let that = this;
                    this.slider = new Flickity(that.$refs.slider, {
                        prevNextButtons: true,
                        pageDots: false,
                        contain: true,
                        groupCells: true,
                        bgLazyLoad: 1,
                        on: {
                            ready: function() {
                                let slides = document.querySelectorAll('#property-slider .property-slider__slide');

                                [].forEach.call(slides, function(slide) {
                                    slide.style.height = "100%";
                                });
                            }
                        }
                    });

                    // if (this.$refs.slider.querySelector('.property-slider__slide').length) {
                    //      rightHeight.init();
                    // }
                },
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
