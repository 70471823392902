if (document.getElementById('error')) {
    Layout.components.error = new Vue({
        el: document.getElementById("error"),
        store,
        data: function () {
            return {
                showError: false
            }
        },
        computed: {
            errorText: {
                get() {
                    return this.$store.state.Layout.errorText;
                },
                set(value) {
                    this.$store.dispatch('Layout/changeErrorText', value)
                }
            }
        },
        watch: {
            errorText(val) {
                const that = this;
                if (val !== null) {
                    that.showError = true;
                }
            },
            showError() {
                const that = this;

                window.setTimeout(function () {
                    that.showError = false;

                    window.setTimeout(function () {
                        if (!that.showError) {
                            that.errorText = null;
                        }
                    }, 400);
                }, 4000);
            }
        },
        mounted: function () {
        },
        methods: {},
        created: function () {
        },
        destroyed: function () {
        }
    });
}
