Properties.store.actions = {
    addToFavorites({commit}, value) {
        commit('addToFavorites', value)
    },
    removeFromFavorites({commit}, value) {
        commit('removeFromFavorites', value)
    },
    changeSortOption({commit}, value) {
        commit('setSortOption', value)
    },
    changeViewMode({commit}, value) {
        commit('setViewMode', value)
    },
    toggleBrochureModal({commit}, value) {
        commit('setShowBrochureModal', value)
    },
    toggleImagesLoading({commit}, value) {
        commit('setImagesLoading', value)
    },
    toggleViewingModal({commit}, value) {
        commit('setShowViewingModal', value)
    },
    toggleOfferModal({commit}, value) {
        commit('setShowOfferModal', value)
    },
    showFilters({commit}, value) {
        commit('setShowFilters', value)
    },
};