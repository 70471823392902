Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-edit-step-2')) {
        ManageProperties.components.editStep2 = new Vue({
            el: document.getElementById("property-edit-step-2"),
            store,
            data: function () {
                return {
                    selectedPackage: null,
                    changedPackage: false,
                    packagePrice: 0,
                    option: 0,
                    extraOption: 0,
                    optionPrice: 0,
                    optionOriginalPrice: 0,
                    extraPrice: 0,
                    extraOriginalPrice: 0,
                    initialized: false
                }
            },
            mixins: [Mixins.submitStep, Mixins.propertyJSON],
            watch: {
                selectedPackage() {
                    if (this.initialized) {
                        if (this.$refs.packages.querySelector('input[name="package_optionID"][checked="checked"]')) {
                            this.$refs.packages.querySelector('input[name="package_optionID"][checked="checked"]').checked = false;
                        }
                        if (this.$refs.packages.querySelector('input[name="package_extraID"][checked="checked"]')) {
                            this.$refs.packages.querySelector('input[name="package_extraID"][checked="checked"]').checked = false;
                        }

                        this.option = 0;
                        this.extraOption = 0;
                        this.optionPrice = 0;
                        this.extraPrice = 0;
                        this.optionOriginalPrice = 0;
                        this.extraOriginalPrice = 0;
                    }
                },
                option(newValue) {
                    if (newValue === 0) {
                        this.optionPrice = 0;
                        this.optionOriginalPrice = 0;
                    }
                },
                extraOption(newValue) {
                    if (newValue === 0) {
                        this.extraPrice = 0;
                        this.extraOriginalPrice = 0;
                    }
                }
            },
            computed: {
                originalPrice() {
                    return (this.packagePrice + this.optionOriginalPrice + this.extraOriginalPrice).toFixed(2).replace('.', ',');
                },
                actualPrice() {
                    return (this.packagePrice + this.optionPrice + this.extraPrice).toFixed(2).replace('.', ',');
                }
            },
            mounted: function () {
                const that = this;
                this.setSelectedPackage().then(function () {
                    that.initialized = true;
                });
            },
            methods: {
                setSelectedPackage() {
                    const that = this;
                    return new Promise(resolve => {
                        let selectedOptionElem = this.$refs.packages.querySelector('input[name="package_optionID"][checked="checked"]');
                        let selectedExtraElem = this.$refs.packages.querySelector('input[name="package_extraID"][checked="checked"]');

                        if (this.$refs.packages.querySelector('.package__input[checked="checked"]')) {
                            that.selectedPackage = parseInt(this.$refs.packages.querySelector('.package__input[checked="checked"]').value);
                            that.packagePrice = parseInt(this.$refs.packages.querySelector('.package--' + that.selectedPackage).dataset.price);
                        }
                        if (selectedOptionElem) {
                            that.option = parseInt(selectedOptionElem.value);
                            that.optionPrice = parseFloat(selectedOptionElem.dataset.price);
                            that.optionOriginalPrice = parseFloat(selectedOptionElem.dataset.priceOriginal);
                        }
                        if (selectedExtraElem) {
                            that.extraOption = parseInt(selectedExtraElem.value);
                            that.extraPrice = parseFloat(selectedExtraElem.dataset.price);
                            that.extraOriginalPrice = parseFloat(selectedExtraElem.dataset.priceOriginal);
                        }

                        resolve();
                    });
                },
                submit: function() {
                    let that = this;
                    this.$validator.validateAll().then(function (valid) {
                        if (! valid) {
                            that.MoveToInstance.move(document.querySelector('.input--invalid').parentElement);
                            return;
                        }

                        let formData = new FormData(that.$el);
                        that.$el.classList.add('form--loading');

                        var object = {};
                        formData.forEach(function(value, key){
                            object[key] = value;
                        });

                        object.package_optionID = that.option;
                        object.package_extraID = that.extraOption;

                        return axios({
                            method: 'POST',
                            url: '//' + window.location.host + '/api/user/properties/' + formData.get('_id'),
                            data: object
                        }).then(function (response) {
                            that.messageText = response.data.message;
                            if (response.data.target) {
                                Barba.Pjax.goTo(response.data.target);
                            } else {
                                that.$el.classList.remove('form--loading');
                            }
                        }).catch(function (error) {
                            that.$el.classList.remove('form--loading');
                            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
                                let message = '';
                                for (let field in error.response.data.errors) {
                                    message += '<br>' + error.response.data.errors[field];
                                }

                                that.errorText = error.response.data.message + message;
                            } else if (error.hasOwnProperty('response') && error.response.hasOwnProperty('message')) {
                                that.errorText = error.response.data.message;
                            }
                        });
                    });
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
