Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.querySelectorAll('.package-card').length) {
        Properties.components.cards = [];

        document.querySelectorAll('.package-card').forEach(packageCard => {
            Properties.components.cards.push(
                new Vue({
                    el: packageCard,
                    store,
                    data: function () {
                        return {
                            priceFormatter: new Intl.NumberFormat('nl-NL', {
                                style: 'currency',
                                currency: 'EUR',
                                minimumFractionDigits: 2
                            }),
                            price: 0,
                            priceOriginal: 0,
                            packageCountryPrice: 0,
                            packageOriginalCountryPrice: 0,
                            packageExtraPrice: 0,
                            packageOriginalExtraPrice: 0,
                            packageFundaPrice: 0,
                            packageOriginalFundaPrice: 0
                        }
                    },
                    mixins: [Mixins.smoothScroll],
                    computed: {
                        totalOriginalPrice() {
                            return this.priceFormatter.format(this.packageOriginalCountryPrice + this.packageOriginalExtraPrice + this.price);
                        },
                        totalPrice() {
                            return this.priceFormatter.format(this.packageCountryPrice + this.packageExtraPrice + this.price);
                        },
                        totalPriceOnce() {
                            return this.priceFormatter.format(this.priceOriginal + this.packageFundaPrice);
                        }
                    },
                    watch: {
                        packageCountryPrice: function(val) {
                            this.packageCountryPrice = val;
                            this.packageOriginalCountryPrice = 0;

                            setTimeout(() => {
                                let price = this.$el.querySelector('.package__option-input--country:checked');
                                if (price) {
                                    this.packageOriginalCountryPrice = parseFloat(price.dataset.originalPrice);
                                }
                            }, 50);
                        },
                        packageExtraPrice: function(val) {
                            this.packageExtraPrice = val;
                            this.packageOriginalExtraPrice = 0;

                            setTimeout(() => {
                                let price = this.$el.querySelector('.package__option-input--extra:checked');
                                if (price) {
                                    this.packageOriginalExtraPrice = parseFloat(price.dataset.originalPrice);
                                }
                            }, 50);
                        },
                        packageFundaPrice: function(val) {
                            this.packageFundaPrice = val;

                            setTimeout(() => {
                                let price = this.$el.querySelector('.package__option-input--funda:checked');
                                if (price) {
                                    this.packageFundaPrice = parseFloat(price.dataset.price);
                                }
                            }, 50);
                        }
                    },
                    mounted: function () {
                        this.setData();
                    },
                    methods: {
                        setData() {
                            this.price = parseFloat(this.$el.dataset.price);
                            this.priceOriginal = parseFloat(this.$el.dataset.originalPrice);
                        },
                        handleInputs: function (selector, event) {
                            let input = event.target;

                            // uncheck alle andere inputs
                            let items = this.$el.getElementsByClassName('package__option-input--' + selector);
                            if (input.checked && input.hasAttribute('data-clicked')) {
                                items[0].click();
                                input.removeAttribute('data-clicked');
                            } else {
                                input.setAttribute('data-clicked', 'true');
                            }
                        },
                    },
                    created: function () {
                    },
                    destroyed: function () {
                    }
                })
            )
        });
    }
});
