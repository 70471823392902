Mixins.addressLookup = {
    data: function() {
        return {
            readyForLookup: false,
            lookup: {
                'zipcode': null,
                'housenumber': null,
                'housenumber_addition': null,
            },
            lookedup: {
                'zipcode': null,
                'housenumber': null,
                'housenumber_addition': null,
            }
        }
    },

    methods: {
        doLookup: function () {
            let country = this.$el.querySelector('input[name="country"]');
            if (! country) {
                country = this.$el.querySelector('[data-name="country"]');
            }

            if (country && country.value !== 1) {
                return false;
            }

            if (this.lookedup.zipcode == null || this.lookedup.housenumber == null) {
                return false;
            }

            let that = this;

            axios({
                method: 'post',
                url: '/api/address-lookup',
                data: this.lookedup
            }).then(function (response) {
                let data = response.data;

                for (let key in data) {
                    let input = that.$el.querySelector('[name="' + key + '"]');
                    let value = data[key];

                    if (! input) {
                        input = that.$el.querySelector('[data-name="' + key + '"]');
                    }

                    if (input && input.tagName.toLowerCase() === 'input') {
                        input.value = value;
                    }
                }
            });
        },
    },

    mounted: function () {
        for (let name in this.lookup) {
            let input = this.$el.querySelector('input[name="' + name + '"]');
            if (input) {
                this.lookup[name] = input.getAttribute('value');
            }
        }

        this.lookedup = JSON.parse(JSON.stringify(this.lookup)); // clone
        this.readyForLookup = true;
    },

    watch: {
        lookup: {
            handler: function(value) {
                let same = (
                    this.lookedup['zipcode'] === this.lookup['zipcode'] &&
                    this.lookedup['housenumber'] === this.lookup['housenumber']
                );

                if (! this.readyForLookup || same) {
                    return false;
                }

                this.lookedup = JSON.parse(JSON.stringify(this.lookup)); // clone
                this.doLookup();
            },
            deep: true
        }
    }
};