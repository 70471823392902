Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-edit-step-8')) {
        ManageProperties.components.editStep8 = new Vue({
            el: document.getElementById("property-edit-step-8"),
            store,
            data: function () {
                return {
                    imagesPark: []
                }
            },
            mixins: [Mixins.submitStep, Mixins.propertyJSON, Mixins.uploadImages, Mixins.deleteImage, Mixins.cleanImages, Mixins.addImages],
            computed: {
                messageText: {
                    get() {
                        return this.$store.state.Layout.messageText;
                    },
                    set(value) {
                        this.$store.dispatch('Layout/changeMessageText', value)
                    }
                },
                imagesParkToSend() {
                    let clonedImages = JSON.parse(JSON.stringify(this.imagesPark));

                    clonedImages.forEach((image, imageIndex) => {
                        image['blob'] = null;
                    });

                    return JSON.stringify(clonedImages);
                },
            },
            mounted: function () {
                this.setParkImages();
            },
            watch: {
                imagesPark: {
                    handler(newValue) {
                        this.$refs.imagesPark.value = JSON.stringify(newValue);
                    },
                    deep: true
                },
            },
            methods: {
                setParkImages() {
                    this.imagesPark = JSON.parse(this.$refs.imagesPark.value);
                },
                customDeleteImage(images, image, inputID) {
                    this.deleteImage(images, image, inputID);
                    this.setImagesToSend();
                },
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
