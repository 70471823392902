Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("video-modal")) {
        Global.components.videoModal = new Vue({
            el: document.getElementById("video-modal"),
            store,
            data() {
                return {
                    video: {}
                }
            },
            mounted() {
                this.video.instance = videojs(this.$refs.video, {
                    controls: true,
                    fluid: true,
                    aspectRatio: '16:9',
                    customVars: {
                        wmode: 'transparent'
                    },
                    techOrder: ["Youtube"]
                });
            },
            computed: {
                showMediaVideoModal: {
                    get() {
                        return this.$store.state.Global.showMediaVideoModal;
                    },
                    set(value) {
                        this.$store.dispatch('Global/toggleMediaVideoModal', value)
                    }
                }
            },
            watch: {
                showMediaVideoModal(val) {
                    if (val) {
                        this.startVideo();
                    } else {
                        this.stopVideo();
                    }
                }
            },
            methods: {
                stopVideo() {
                    this.video.instance.pause();
                    this.video.instance.controls(false);
                    this.video.playing = false;
                },
                startVideo() {
                    this.video.instance.play();
                    this.video.instance.controls(false);
                    this.video.playing = true;
                },
                toggleVideo() {
                    if (this.video.playing) {
                        this.stopVideo();
                    } else {
                        this.startVideo();
                    }
                }
            }
        });
    }
});