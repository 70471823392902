Properties.api = {
    favorites(url) {
        return axios.get(url).then(response => {
            return response.data;
        });
    },
    overview(url) {
        return axios.get(url).then(response => {
            return response.data;
        });
    }
};
