Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-title-heading')) {
        Properties.components.propertyText = new Vue({
            el: document.getElementById("property-title-heading"),
            store,
            data: function () {
                return {}
            },
            computed: {
                showViewingModal: {
                    get() {
                        return this.$store.state.Properties.showViewingModal;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/toggleViewingModal', value)
                    }
                },
                showOfferModal: {
                    get() {
                        return this.$store.state.Properties.showOfferModal;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/toggleOfferModal', value)
                    }
                },
            }
        });
    }
});
