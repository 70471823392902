Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-react-buttons')) {
        Properties.components.propertyReactButtons = new Vue({
            el: document.getElementById("property-react-buttons"),
            store,
            data: function () {
                return {
                }
            },
            mixins: [Mixins.smoothScroll],
            computed: {
                showOfferModal: {
                    get() {
                        return this.$store.state.Properties.showOfferModal;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/toggleOfferModal', value)
                    }
                },
                showViewingModal: {
                    get() {
                        return this.$store.state.Properties.showViewingModal;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/toggleViewingModal', value)
                    }
                }
            },
            mounted: function () {
            },
            watch: {
            },
            methods: {
            },
        });
    }
});
