Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('media-slider')) {
        Global.components.mediaSlider = new Vue({
            el: document.getElementById("media-slider"),
            store,
            mixins: [Mixins.submitForm],
            data: function () {
                return {
                    mainSlider: null,
                    sliderNav: null,
                }
            },
            computed: {
                showMediaVideoModal: {
                    get() {
                        return this.$store.state.Global.showMediaVideoModal;
                    },
                    set(value) {
                        this.$store.dispatch('Global/toggleMediaVideoModal', value)
                    }
                },
                showSliderModal: {
                    get() {
                        return this.$store.state.Global.showSliderModal;
                    },
                    set(value) {
                        this.$store.dispatch('Global/toggleSliderModal', value)
                    }
                }
            },
            mounted: function () {
                this.initializeSliders();
            },
            methods: {
                initializeSliders() {
                    this.mainSlider = new Flickity(this.$refs.mainSlider, {
                        adaptiveHeight: true,
                        prevNextButtons: false,
                        pageDots: false,
                        contain: true,
                        imagesLoaded: true,
                        lazyLoad: true,
                    });

                    this.sliderNav = new Flickity(this.$refs.sliderNav, {
                        asNavFor: this.$refs.mainSlider,
                        prevNextButtons: false,
                        pageDots: false,
                        contain: true,
                    });
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
