Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-edit-step-9')) {
        ManageProperties.components.editStep9 = new Vue({
            el: document.getElementById("property-edit-step-9"),
            store,
            data: function () {
                return {
                    imagesSurrounding: []
                }
            },
            mixins: [Mixins.submitStep, Mixins.propertyJSON, Mixins.uploadImages, Mixins.deleteImage, Mixins.cleanImages, Mixins.addImages],
            watch: {
                imagesSurrounding: {
                    handler(newValue) {
                        this.$refs.imagesSurrounding.value = JSON.stringify(newValue);
                    },
                    deep: true
                },
            },
            computed: {
                imagesSurroundingToSend() {
                    let clonedImages = JSON.parse(JSON.stringify(this.imagesSurrounding));

                    clonedImages.forEach((image, imageIndex) => {
                        image['blob'] = null;
                    });

                    return JSON.stringify(clonedImages);
                },
            },
            mounted: function () {
                this.setSurroundingImages();
            },
            methods: {
                setSurroundingImages() {
                    this.imagesSurrounding = JSON.parse(this.$refs.imagesSurrounding.value);
                },
                customDeleteImage(images, image, inputID) {
                    this.deleteImage(images, image, inputID);
                    this.setImagesToSend();
                },
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
