translations.de = {
    notImage: 'Es können nur JPG/JPEG Bilder hochgeladen werden.',
    imageTooBig: 'Das Bild darf nicht größer als{{ limit }}mb sein',
    selectProvince: 'Wählen Sie die Provinz',
    provinces: [
        {
            id: 5,
            value: 'Groningen'
        },
        {
            id: 3,
            value: 'Friesland'
        },
        {
            id: 1,
            value: 'Drenthe'
        },
        {
            id: 9,
            value: 'Overijssel'
        },
        {
            id: 4,
            value: 'Gelderland'
        },
        {
            id: 2,
            value: 'Flevoland'
        },
        {
            id: 7,
            value: 'Nord-Brabant'
        },
        {
            id: 6,
            value: 'Limburg'
        },
        {
            id: 11,
            value: 'Zeeland'
        },
        {
            id: 12,
            value: 'Süd-Holland'
        },
        {
            id: 10,
            value: 'Utrecht'
        },
        {
            id: 8,
            value: 'Nord-Holland'
        }
    ],
    selectProvince: 'Selecteer provincie'
};