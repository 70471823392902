Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-intro')) {
        Properties.components.propertyText = new Vue({
            el: document.getElementById("property-intro"),
            store,
            data: function () {
                return {}
            },
            mixins: [Mixins.convertGeoToPixel, Mixins.manageFavorites, Mixins.smoothScroll, Mixins.manageFavorites],
            computed: {
                showBrochureModal: {
                    get() {
                        return this.$store.state.Properties.showBrochureModal;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/toggleBrochureModal', value)
                    }
                },
                showViewingModal: {
                    get() {
                        return this.$store.state.Properties.showViewingModal;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/toggleViewingModal', value)
                    }
                }
            },
            mounted: function () {
                this.positionMapIcon();
            },
            methods: {
                positionMapIcon() {
                    let mapCoords = this.convertGeoToPixel(this.$refs.mapIcon.dataset.x, this.$refs.mapIcon.dataset.y, this.$refs.map.clientWidth, this.$refs.map.clientHeight);

                    let leftPercentage = (mapCoords.x / this.$refs.map.clientWidth) * 100;
                    let topPercentage = (mapCoords.y / this.$refs.map.clientHeight) * 100;

                    this.$refs.mapIcon.style.left = leftPercentage + "%";
                    this.$refs.mapIcon.style.top = topPercentage + "%";
                },
            },
        });
    }
});
