ManageAccount.api = {
    store(data) {
        return axios.post(window.location.href, data);
    },

    show() {
        return axios.get('/api/user');
    },

    update(data) {
        return axios.post('/api/user', data);
    },

    check(data) {
        return axios.post('/api/user/check', data);
    }
};
