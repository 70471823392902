Mixins.addImages = {
    computed: {
        imagesLoading: {
            get() {
                return this.$store.state.Properties.imagesLoading;
            },
            set(value) {
                this.$store.dispatch('Properties/toggleImagesLoading', value)
            }
        },
    },
    methods: {
        addImages(e, images, limit) {
            const that = this;
            that.imagesLoading = true;

            this.uploadImages(e, images.length, limit).then(responseImages => {
                that.imagesLoading = false;

                responseImages.forEach(image => {

                    let imageObj = {
                        path: image.path,
                        blob: image.blob,
                        name: image.name,
                        id: null
                    };

                    images.push(imageObj);
                    setTimeout(function() {
                        window.attachDragEvents();
                    }, 1000);
                });
            }).catch(error => {
                that.imagesLoading = false;
                console.error(error);
            });
        },
    }
};