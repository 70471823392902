ManageProperties.api = {
    index() {
        return axios.get('/api/user/properties');
    },
    store(data) {
        return axios.post('/api/user/properties', data);
    },

    show(id) {
        return axios.get('/api/user/properties/' + id);
    },
    destroy(data) {
        data.append('_method', 'DELETE');
        return axios.post('/api/user/properties/' + data.get('_id'), data);
    },
    createPayment(data) {
        return axios.post('/api/user/properties/payment', data);
    }
};
