Barba.Dispatcher.on("transitionCompleted", function () {

    if (document.querySelectorAll('.property-block').length) {
        ManageProperties.components.create = [];

        document.querySelectorAll('.property-block').forEach(propertyBlock => {
            ManageProperties.components.create.push(
                new Vue({
                    el: propertyBlock,
                    store,
                    mixins: [Mixins.convertGeoToPixel, Mixins.manageFavorites, Mixins.positionMapIcon],
                    data: function () {
                        return {
                        }
                    },
                    computed: {
                    },
                    methods: {
                    },
                })
            )
        });
    }
});