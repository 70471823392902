Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("slider-modal")) {
        Global.components.videoModal = new Vue({
            el: document.getElementById("slider-modal"),
            store,
            data() {
                return {
                    slider: null,
                }
            },
            mounted() {
                this.initializeSlider();
            },
            computed: {
                showSliderModal: {
                    get() {
                        return this.$store.state.Global.showSliderModal;
                    },
                    set(value) {
                        this.$store.dispatch('Global/toggleSliderModal', value)
                    }
                }
            },
            watch: {
                showSliderModal: function (value) {
                    if (value) {
                        this.slider.viewFullscreen();
                    } else {
                        this.slider.exitFullscreen();
                    }
                }
            },
            methods: {
                initializeSlider() {
                    let images = this.$el.querySelectorAll('img');
                    let that = this;

                    this.slider = new Flickity(this.$el, {
                        fullscreen: true,
                        lazyLoad: true,
                        on: {
                            ready: function() {
                                if (images) {
                                    images.forEach(image => {
                                        image.removeAttribute('sizes');
                                    });
                                }
                            },
                            fullscreenChange: function (isFullscreen) {
                                that.slider.resize();
                                that.showSliderModal = isFullscreen;
                            }
                        }
                    });
                }
            }
        });
    }
});
