Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("order-sign-form")) {
        Global.components.orderSign = new Vue({
            el: document.getElementById("order-sign-form"),
            store,
            data: function () {
                return {
                    hasCaptcha: false,
                    captchaExecuted: false,
                    method: 'ideal'
                }
            },
            mixins: [Mixins.addressLookup,Mixins.submitForm],
            components: {
                'vue-recaptcha': VueRecaptcha
            },
            mounted() {
            },
            computed: {

            },
            watch: {

            },
            methods: {
                submit() {
                    const that = this;
                    this.$validator.validateAll().then(result => {
                        let analytics = that.$el.dataset.analytics;

                        if (result) {
                            if (!this.hasCaptcha || this.captchaExecuted) {
                                that.submitForm(that.$el, analytics);
                            } else {
                                this.$refs.recaptcha.execute();
                            }
                        }
                    });
                }
            },
        });
    }
});