Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('filters')) {
        Properties.components.filters = new Vue({
            el: document.getElementById("filters"),
            store,
            data: function () {
                return {
                    selectedCountry: 1,
                    hoveredProvince: '',
                    selectedProvinces: [],
                    filtersExpanded: false,
                    viewModeSet: false,
                    mouseCoords: {
                        x: 0,
                        y: 0
                    }
                }
            },
            computed: {
                sort: {
                    get() {
                        return this.$store.state.Properties.sortOption;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/changeSortOption', value)
                    }
                },
                showFilters: {
                    get() {
                        return this.$store.state.Properties.showFilters;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/showFilters', value)
                    }
                },
                view: {
                    get() {
                        return this.$store.state.Properties.viewMode;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/changeViewMode', value)
                    }
                },
                allProvincesSelected() {
                    return this.selectedProvinces.length === 12;
                },
                selectedProvincesText() {
                    let result = [];

                    this.selectedProvinces.forEach((id) => {
                        let index = translations[document.documentElement.lang].provinces.findIndex((province) => {
                            return province.id === id;
                        });

                        if (index > -1) {
                            result.push(translations[document.documentElement.lang].provinces[index].value);
                        }
                    });

                    return result.join(', ');
                }
            },
            created: function() {
            },
            mixins: [Mixins.mousePosition, Mixins.queryString],
            mounted: function () {
                let selectedCountry = this.$refs.filtersForm.querySelector('[name="country_id"] option[selected="selected"]');
                if (selectedCountry) {
                    this.selectedCountry = parseInt(selectedCountry.getAttribute('value'));
                }

                // let view = this.getQueryStringParameter(window.location.href, 'view');
                // this.view = view ? view : 'list';
                // this.viewModeSet = true;

                setTimeout(() => {
                    let provinces = this.$refs.filtersForm.querySelectorAll('[name="provinces[]"]');
                    if (! provinces) {
                        return;
                    }

                    for (let i = 0; i < provinces.length; i++) {
                        let province = provinces[i];
                        let value = parseInt(province.value);

                        this.toggleProvince(value, province.getAttribute('checked'))
                    }
                }, 50);

                this.initProvinceListeners();
            },
            watch: {
                sort(val) {
                    this.$refs.sortField.value = val;
                    this.submit();
                },
                // view(val) {
                //     if (this.viewModeSet && this.$refs.viewField.value != val) {
                //         this.$refs.viewField.value = val;
                //         this.submit();
                //     }
                // },
                selectedCountry(val) {
                    this.selectedProvinces = [];
                }
            },
            methods: {
                toggleProvince(id, active) {
                    id = parseInt(id);
                    let index = this.selectedProvinces.indexOf(id);
                    let path = this.$refs.filtersMap.querySelector('path[data-id="' + id + '"]');

                    if (active) {
                        if (path) {
                            path.classList.add('province-active');
                        }

                        if (index < 0) {
                            this.selectedProvinces.push(id);
                        }
                    } else {
                        if (path) {
                            path.classList.remove('province-active');
                        }

                        if (index >= 0) {
                            this.selectedProvinces.splice(index, 1);
                        }
                    }

                    this.$forceUpdate();
                },

                initProvinceListeners() {
                    this.$refs.filtersMap.querySelectorAll('path').forEach((path) => {
                        if (! path.hasAttribute('data-id')) {
                            return;
                        }

                        let id = parseInt(path.getAttribute('data-id'));

                        path.addEventListener('click', (evt) => {
                            let index = this.selectedProvinces.indexOf(id);
                            this.toggleProvince(id, (index < 0));
                        });

                        path.addEventListener('mouseenter', (evt) => {
                            let index = translations[document.documentElement.lang].provinces.findIndex(province => {
                                return province.id === id;
                            });

                            this.hoveredProvince = translations[document.documentElement.lang].provinces[index].value;
                        });

                        path.addEventListener('mouseleave', (e) => {
                            this.hoveredProvince = '';
                        });
                    });
                },

                selectAllProvinces() {
                    if (! this.allProvincesSelected) {
                        this.selectedProvinces = [];

                        this.$refs.filtersMap.querySelectorAll('path').forEach((path) => {
                            if (! path.hasAttribute('data-id')) {
                                return;
                            }

                            let id = parseInt(path.getAttribute('data-id'));
                            this.toggleProvince(id, true);
                        });
                    } else {
                        this.$refs.filtersMap.querySelectorAll('path').forEach((path) => {
                            path.classList.remove('province-active');
                        });

                        this.selectedProvinces = [];
                    }
                },

                submit() {
                    let url = location.protocol + '//' + location.host + location.pathname;

                    let query = serialize(this.$refs.filtersForm);
                    if (query) {
                        url += '?' + query;
                    }

                    Barba.Pjax.goTo(url);
                },

                setSelectedProvincePosition(e) {
                    this.mouseCoords = this.getMousePositionToElement(e, this.$refs.filtersMap);
                },

                resetFilters() {
                    let url = window.location.href.split("?")[0];

                    if (this.view) {
                        url = this.updateQueryStringParameter(url, 'view', this.view);
                    }

                    window.location = url;
                }
            },
        });
    }
});
