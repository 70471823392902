Mixins.manageFavorites = {
    data: function () {
        return {
        }
    },
    computed : {
        favorites() {
            return this.$store.state.Properties.favorites;
        },
    },
    methods: {
        isFavorite(id) {
            return this.$store.getters['Properties/isFavorite'](id)
        },
        toggleFavorites(id) {
            if(this.isFavorite(id)) {
                this.removeFromFavorites(id);
            } else {
                this.addToFavorites(id);
            }
        },
        addToFavorites(id) {
            this.$store.dispatch('Properties/addToFavorites', id.toString());
            window.localStorage.setItem('favorites', this.favorites);
        },
        removeFromFavorites(id) {
            this.$store.dispatch('Properties/removeFromFavorites', id.toString());
            window.localStorage.setItem('favorites', this.favorites);
        }
    },
};