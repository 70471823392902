Layout.store.actions = {
    changeMenuExpanded({commit}, value) {
        commit('setMenuExpanded', value)
    },
    changeSearchExpanded({commit}, value) {
        commit('setSearchExpanded', value)
    },
    changeErrorText({commit}, value) {
        commit('setErrorText', value)
    },
    changeMessageText({commit}, value) {
        commit('setMessageText', value)
    },
};