Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('account-create')) {
        ManageAccount.components.create = new Vue({
            el: document.getElementById("account-create"),
            store,
            data: function () {
                return {
                    result: [],
                    confirmation: null,
                    type: ''
                }
            },
            mixins: [Mixins.checkUsername, Mixins.addressLookup],
            computed: {
            },
            mounted: function () {
            },
            methods: {
                submit() {
                    if (! this.$refs.hasOwnProperty(this.type)) {
                        return;
                    }

                    let that = this;
                    let form = this.$refs[this.type];
                    let data = new FormData(form);
                    data.append('type', this.type);

                    this.$validator.validateAll().then(result => {
                        if (result) {
                            axios.post(form.action, data).then(function(response) {
                                that.result = response.data;

                                // todo @geert message text zetten

                                if (response.data.target) {
                                    Barba.Pjax.goTo(response.data.target);
                                }
                            });
                        }
                    });
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
