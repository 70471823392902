Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('account-edit')) {
        ManageAccount.components.edit = new Vue({
            el: document.getElementById("account-edit"),
            store,
            data: function () {
                return {
                    result: [],
                    password_current: null,
                    password: null,
                    password_confirmation: null,
                }
            },
            mixins: [Mixins.checkUsername, Mixins.addressLookup],
            computed: {
                messageText: {
                    get() {
                        return this.$store.state.Layout.messageText;
                    },
                    set(value) {
                        this.$store.dispatch('Layout/changeMessageText', value)
                    }
                },
                errorText: {
                    get: function() {
                        return this.$store.state.Layout.errorText;
                    },
                    set: function(value) {
                        this.$store.dispatch('Layout/changeErrorText', value)
                    }
                }
            },
            mounted: function () {
            },
            methods: {
                submit() {
                    let that = this;
                    this.$validator.validateAll().then(result => {
                        if (result) {
                            that.$store.dispatch('ManageAccount/update', new FormData(this.$el))
                                .then(function (response) {
                                    that.messageText = response.data.message;
                                    if (response.data.target) {
                                        Barba.Pjax.goTo(response.data.target);
                                    } else {
                                        that.$el.classList.remove('form--loading');
                                    }

                                    that.password_current = null;
                                    that.password = null;
                                    that.password_confirmation = null;
                                }).catch(function (error) {
                                    that.$el.classList.remove('form--loading');
                                    if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
                                        let message = '';
                                        for (let field in error.response.data.errors) {
                                            message += '<br>' + error.response.data.errors[field];
                                        }

                                        that.errorText = error.response.data.message + message;
                                    } else if (error.hasOwnProperty('response') && error.response.hasOwnProperty('message')) {
                                        that.errorText = error.response.data.message;
                                    }
                                });
                        }
                    })
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
