translations.nl = {
    notImage: 'Er mogen alleen JPG/JPEG afbeeldingen geupload worden.',
    TooManyImages: 'Er mogen niet meer dan {{ limit }} afbeeldingen geupload worden.',
    imageTooBig: 'De afbeelding mag niet groter zijn dan {{ limit }}mb',
    provinces: [
        {
            id: 5,
            value: 'Groningen'
        },
        {
            id: 3,
            value: 'Friesland'
        },
        {
            id: 1,
            value: 'Drenthe'
        },
        {
            id: 9,
            value: 'Overijssel'
        },
        {
            id: 4,
            value: 'Gelderland'
        },
        {
            id: 2,
            value: 'Flevoland'
        },
        {
            id: 7,
            value: 'Noord-Brabant'
        },
        {
            id: 6,
            value: 'Limburg'
        },
        {
            id: 11,
            value: 'Zeeland'
        },
        {
            id: 12,
            value: 'Zuid-Holland'
        },
        {
            id: 10,
            value: 'Utrecht'
        },
        {
            id: 8,
            value: 'Noord-Holland'
        }
    ],
    selectProvince: 'Selecteer provincie'
};