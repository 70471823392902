Mixins.positionMapIcon = {
    mounted: function () {
        this.positionMapIcon();
    },
    methods: {
        positionMapIcon: function (latitude, longitude, clientWidth, clientHeight) {
            if (this.$refs.mapIcon && this.$refs.mapIcon) {
                let mapCoords = this.convertGeoToPixel(this.$refs.mapIcon.dataset.x, this.$refs.mapIcon.dataset.y, this.$refs.map.clientWidth, this.$refs.map.clientHeight);

                let leftPercentage = (mapCoords.x / this.$refs.map.clientWidth) * 100;
                let topPercentage = (mapCoords.y / this.$refs.map.clientHeight) * 100;

                this.$refs.mapIcon.style.left = leftPercentage + "%";
                this.$refs.mapIcon.style.top = topPercentage + "%";
            }
        },
    }
};