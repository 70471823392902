Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-characteristics')) {
        Properties.components.propertyCharacteristics = new Vue({
            el: document.getElementById("property-characteristics"),
            mixins: [Mixins.smoothScroll],
            data: function () {
                return {
                    activeTab: 1
                }
            },
            watch: {
                activeTab() {
                    let target = this.$el.getElementsByClassName('property-characteristics__row')[this.activeTab - 1];

                    setTimeout(() => {
                        this.MoveToInstance.move(target);
                    });
                }
            },
        });
    }
});
