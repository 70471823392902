Barba.Dispatcher.on("transitionCompleted", function () {

    // Create Element.classList() function if not exist
    if (!Object.getOwnPropertyDescriptor(Element.prototype,'classList')){
        if (HTMLElement&&Object.getOwnPropertyDescriptor(HTMLElement.prototype,'classList')){
            Object.defineProperty(Element.prototype,'classList',Object.getOwnPropertyDescriptor(HTMLElement.prototype,'classList'));
        }
    }

    // Create Element.remove() function if not exist
    if (!('remove' in Element.prototype)) {
        Element.prototype.remove = function() {
            if (this.parentNode) {
                this.parentNode.removeChild(this);
            }
        };
    }

    moment.locale('nl');

    let Global = new Vue({
        store,
        mixins: [Mixins.smoothScroll],
        data() {
            return {
                bLazy: null,
                MoveToInstance: new MoveTo({
                    tolerance: 100,
                    duration: 800
                }),
                formDictionary: {
                    nl: {
                        _default: (field) => `Dit veld is niet correct ingevuld.`,
                        after: (field, [target, inclusion]) => `Dit veld moet groter ${inclusion ? 'of gelijk aan ' : ''} ${target}.`,
                        alpha_dash: (field) => `Dit veld mag alleen alfanumerieke karakters, strepen en onderstrepingstekenen bevatten.`,
                        alpha_num: (field) => `Dit veld mag alleen alfanumerieke karakters bevatten.`,
                        alpha_spaces: (field) => `Dit veld mag alleen alfanumerieke karakters en spaties bevatten.`,
                        alpha: (field) => `Dit veld mag alleen alfabetische karakters bevatten.`,
                        before: (field, [target, inclusion]) => `De waarde van dit veld moet kleiner ${inclusion ? 'of gelijk aan' : ''} ${target} zijn.`,
                        between: (field, [min, max]) => `De waarde van dit veld moet tussen ${min} en ${max} zijn.`,
                        confirmed: (field) => `Dit bevestigingsveld komt niet overeen.`,
                        credit_card: (field) => `Dit veld is ongeldig.`,
                        date_between: (field, [min, max]) => `De datum moet tussen ${min} en ${max} zijn.`,
                        date_format: (field, [format]) => `Dit veld moet het volgende formaat hebben: ${format}.`,
                        decimal: (field, [decimals = '*'] = []) => `Dit veld mag alleen numerieke, en${!decimals || decimals === '*' ? ' ' : decimals}decimale nummers bevatten.`,
                        digits: (field, [length]) => `Dit veld moet ${length} nummers bevatten.`,
                        dimensions: (field, [width, height]) => `De dimensies voor dit veld moet ${width} pixels breed en ${height} pixels hoog zijn.`,
                        email: (field) => `Dit veld moet een geldig e-mailadres bevatten.`,
                        ext: (field) => `Dit veld moet een correct bestand bevatten.`,
                        image: (field) => `Dit veld moet een afbeelding bevatten.`,
                        included: (field) => `Dit veld moet een geldige waarde bevatten.`,
                        integer: (field) => `Dit veld moet een nummer zijn.`,
                        ip: (field) => `Dit veld moet een veilig ip adres zijn.`,
                        length: (field, [length, max]) => {
                            if (max) {
                                return `Dit veld moet minimaal ${length} karakters en maximaal ${max} karakters bevatten.`;
                            }

                            return `Dit veld moet minimaal ${length} karakters lang zijn.`;
                        },
                        max: (field, [length]) => `Dit veld mag niet meer karakters bevatten dan ${length}.`,
                        max_value: (field, [max]) => `Dit veld mag een maximale waarde hebben van ${max}.`,
                        mimes: (field) => `Dit veld moet Dit juiste type bestand bevatten.`,
                        min: (field, [length]) => `Dit veld moet minimaal ${length} karakters zijn.`,
                        min_value: (field, [min]) => `Dit veld moet een minimale waarde hebben van ${min}.`,
                        excluded: (field) => `Dit veld moet een geldige waarde bevatten`,
                        numeric: (field) => `Dit veld mag alleen numerieke karakters bevatten.`,
                        regex: (field) => `Dit veld is niet correct ingevoerd.`,
                        required: (field) => `Dit veld is verplicht.`,
                        size: (field, [size]) => `De bestandsgrootte van dit veld mag niet groter zijn dan ${formatFileSize(size)}.`,
                        url: (field) => `Dit veld moet een valide URL zijn.`
                    },
                    de: {
                        _default: (field) => `Dieses Feld ist nicht korrekt eingetragen.`,
                        after: (field, [target, inclusion]) => `Dieses Feld muss größer sein ${inclusion ? 'oder gleich ' : ''} ${target}.`,
                        alpha_dash: (field) => `Dieses Feld darf nur alphanumerische Zeichen, Streifen und Unterstriche enthalten.`,
                        alpha_num: (field) => `Dieses Feld darf nur alphanumerische Zeichen enthalten.`,
                        alpha_spaces: (field) => `Dieses Feld darf nur alphanumerische Zeichen und Leerzeichen enthalten.`,
                        alpha: (field) => `Dieses Feld darf nur alphabetische Zeichen enthalten.`,
                        before: (field, [target, inclusion]) => `Der Wert dieses Feldes muss kleiner sein ${inclusion ? 'oder gleich' : ''} ${target} sind.`,
                        between: (field, [min, max]) => `Der Wert dieses Feldes muss zwischen ${min} und ${max} liegen.`,
                        confirmed: (field) => `Dieses Bestätigungsfeld stimmt nicht überein.`,
                        credit_card: (field) => `Dieses Feld ist ungültig.`,
                        date_between: (field, [min, max]) => `Das Datum muss zwischen ${min} en ${max} liegen.`,
                        date_format: (field, [format]) => `Dieses Feld muss das folgende Format haben: ${format}.`,
                        decimal: (field, [decimals = '*'] = []) => `Dieses Feld kann nur numerische und${!decimals || decimals === '*' ? ' ' : decimals}Dezimalzahlen enthalten.`,
                        digits: (field, [length]) => `Dieses Feld muss ${length} Zahlen enthalten.`,
                        dimensions: (field, [width, height]) => `Die Abmessungen für dieses Feld müssen ${width} Pixel breit und ${height} Pixel hoch sein.`,
                        email: (field) => `Dieses Feld muss eine gültige E-Mail-Adresse enthalten.`,
                        ext: (field) => `Dieses Feld muss eine korrekte Datei enthalten.`,
                        image: (field) => `Dieses Feld muss ein Bild enthalten.`,
                        included: (field) => `Dieses Feld muss einen gültigen Wert enthalten.`,
                        integer: (field) => `Dieses Feld muss eine Zahl sein.`,
                        ip: (field) => `Dieses Feld muss eine sichere IP-Adresse sein.`,
                        length: (field, [length, max]) => {
                            if (max) {
                                return `Dieses Feld muss mindestens ${length} und höchstens ${max} Zeichen enthalten.`;
                            }
                            return `Dieses Feld muss mindestens ${length} Zeichen enthalten.`;
                        },
                        max: (field, [length]) => `Dieses Feld darf nicht mehr Zeichen enthalten als ${length}.`,
                        max_value: (field, [max]) => `Dieses Feld darf einen Maximalwert von haben ${max}.`,
                        mimes: (field) => `Dieses Feld muss den richtigen Dateityp enthalten.`,
                        min: (field, [length]) => `Dieses Feld muss mindestens ${length} Zeichen enthalten.`,
                        min_value: (field, [min]) => `Dieses Feld muss einen Mindestwert von haben ${min}.`,
                        excluded: (field) => `Dieses Feld muss einen gültigen Wert enthalten`,
                        numeric: (field) => `Dieses Feld darf nur numerische Zeichen enthalten.`,
                        regex: (field) => `Dieses Feld wurde nicht korrekt eingegeben.`,
                        required: (field) => `Dieses Feld ist erforderlich.`,
                        size: (field, [size]) => `Die Dateigröße dieses Feldes darf nicht überschritten werden ${formatFileSize(size)}.`,
                        url: (field) => `Dieses Feld muss eine gültige URL sein.`
                    }
                },
            }
        },
        directives: {
            clickOutside: {
                bind: function (el, binding, vNode) {
                    // Provided expression must evaluate to a function.
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name;
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
                        if (compName) {
                            warn += `Found in component '${compName}'`
                        }
                    }
                    // Define Handler and cache it on the element
                    const bubble = binding.modifiers.bubble;
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    };
                    el.__vueClickOutside__ = handler;

                    // add Event Listeners
                    document.addEventListener('click', handler)
                },
                unbind: function (el, binding) {
                    // Remove Event Listeners
                    document.removeEventListener('click', el.__vueClickOutside__);
                    el.__vueClickOutside__ = null
                }
            }
        },
        methods: {
            initBlazy() {
                this.bLazy = new Blazy({
                    breakpoints: [{
                        width: 0,
                        src: 'data-src-small'
                    }, {
                        width: 640,
                        src: 'data-src-medium'
                    }, {
                        width: 1024,
                        src: 'data-src-large'
                    }, {
                        width: 1200,
                        src: 'data-src-xlarge'
                    }, {
                        width: 1440,
                        src: 'data-src-xxlarge'
                    }]
                })
            },
            initBarba() {
                let FadeTransition = Barba.BaseTransition.extend({
                    start: function () {
                        // As soon the loading is finished and the old page is faded out, let's fade the new page
                        Promise
                            .all([this.newContainerLoading, this.fadeOut()])
                            .then(this.fadeIn.bind(this));
                    },

                    fadeOut: function () {
                        return new Promise((resolve, reject) => {
                            document.querySelector('.barba-bg').classList.add('barba-bg--visible');
                            window.setTimeout(function () {
                                resolve();
                            }, 200);
                        });
                    },

                    fadeIn: function () {
                        let _this = this;
                        (this.oldContainer).style.display = 'none';

                        window.scrollTo(0, 0);


                        document.querySelector('.barba-bg').classList.remove('barba-bg--visible');

                        setTimeout(function(){
                            window.store.dispatch('Layout/changeMenuExpanded', false);
                        }, 200);

                        _this.done();
                    }
                });

                Barba.Pjax.getTransition = function () {
                    return FadeTransition;
                };
            },
            initVeevalidate() {
                const that = this;

                Vue.use(VeeValidate, {
                    locale: document.documentElement.lang,
                    events: 'blur|submit|change',
                    classes: true,
                    invalidateFalse: true,
                    classNames: {
                        invalid: 'input--invalid',
                        valid: 'input--valid'
                    },
                    dictionary: {
                        nl: {messages: that.formDictionary['nl']},
                        de: {messages: that.formDictionary['de']},
                    }
                });
            },
            initAxios() {
                // Add a response interceptor
                axios.interceptors.response.use(function (response) {
                    // Do something with response data
                    return response;
                }, function (error) {
                    // Do something with response error
                    // todo error handling
                    return Promise.reject(error);
                });

                let html = document.querySelector('html');

                if (html) {
                    window.axios.defaults.headers.common['X-LANGUAGE'] = html.lang;
                }


            },
            initViewportHeight: function () {

                let setViewportHeight = function () {
                    let vh = window.innerHeight;
                    document.documentElement.style.setProperty('--vh', `${vh}px`);
                };

                window.addEventListener('resize', function () {
                    setViewportHeight();
                });

                setViewportHeight();

                setTimeout(function () {
                    setViewportHeight();
                }, 250);
            },
            initExpandableContent() {
                let expandables = document.getElementsByClassName('expandable');
                for (let i = 0; i < expandables.length; i++) {
                    let expandable = expandables[i];

                    let content = expandable.getElementsByClassName('expandable__content')[0];
                    if (content) {
                        let maxHeight = parseInt(window.getComputedStyle(content).fontSize, 10);
                        maxHeight *= content.dataset.lines;

                        content.style.maxHeight = maxHeight + 'px';
                    }

                    let toggle = expandable.getElementsByClassName('expandable__toggle')[0];
                    if (toggle) {
                        toggle.addEventListener('click', function (e) {
                            e.preventDefault();
                            expandable.classList.toggle('-expanded');
                            toggle.classList.toggle('-expanded');
                        });
                    }
                }
            },
            initIntro() {
                let intro = document.getElementById('welcome-intro');
                if (! intro) {
                    return;
                }

                if (typeof(Storage) === 'undefined' || localStorage.animationPlayed || ('backoffice' in document.body.dataset)) {
                    intro.remove();
                    return;
                }

                let script = document.createElement('script');
                script.onload = function () {
                    localStorage.animationPlayed = true;
                };
                script.src = '//' + window.location.host + '/js/intro.js';
                document.head.appendChild(script);
            },

            initPagination: function () {
                let paginations = document.getElementsByClassName('pagination');
                if (paginations) {
                    for(let i = 0; i < paginations.length; i++) {
                        let item = paginations[i];
                        let active = item.getElementsByClassName('pagination__list-link--active')[0];

                        if (active) {
                            item.scrollLeft = active.offsetLeft - 30;
                        }
                    }
                }
            }
        },
        mounted() {
        },
        created() {
            this.$store.state.Layout.menuExpanded = false;

            this.initBlazy();
            this.initBarba();
            this.initVeevalidate();
            this.initAxios();
            this.initExpandableContent();
            this.initIntro();
            this.initPagination();
            this.initViewportHeight();
        }
    });
});

Barba.Dispatcher.on('initStateChange', function() {
    let el = document.getElementById('gtag');

    if (el && el.hasAttribute('data-id')) {
        // modify to your needs
        var path = (window.location.href).replace(window.location.origin, '').toLowerCase();

        gtag('js', new Date());

        gtag('config', el.getAttribute('data-id'), {
            'page_title': document.title,
            'page_path': path,
            'anonymize_ip': el.hasAttribute('data-anonymize')
        });
    }
});

document.addEventListener("DOMContentLoaded", function () {
    Barba.Pjax.cacheEnabled = false;
    Barba.Pjax.start();
});

/**
 * Toevoeging voor het correct tracken in analytics
 *
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
 */
Barba.Dispatcher.on('initStateChange', function () {
    if (Barba.HistoryManager.history.length <= 1) {
        return;
    }

    // send statics by Google Analytics(analytics.js) or Google Tag Manager
    if (typeof ga === 'function' && typeof ga.getAll === 'function') {
        var trackers = ga.getAll();
        trackers.forEach(function (tracker) {
            ga(tracker.get('name') + '.set', 'page', location.pathname);
            ga(tracker.get('name') + '.send', 'pageview');
        });
    }
});

var dragSrcEl = null;
var dragContainer = document.getElementById('dragContainer');

function handleDragStart(e) {
    this.style.opacity = '0.4';

    dragSrcEl = this;

    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', this.innerHTML);
}

function handleDragOver(e) {
    if (e.preventDefault) {
        e.preventDefault();
    }

    e.dataTransfer.dropEffect = 'move';

    return false;
}

function handleDragEnter(e) {
    this.classList.add('over');
}

function handleDragLeave(e) {
    this.classList.remove('over');
}

function handleDrop(e) {
    if (e.stopPropagation) {
        e.stopPropagation();
    }

    if (dragSrcEl != this) {
        dragSrcEl.parentNode.insertBefore(dragSrcEl, this);
        let dragHiddenInput = document.getElementsByName('images')[0];

        let jsonInput = JSON.parse(dragHiddenInput.value);
        let currentCols = document.getElementsByClassName('dashboard-images__image-wrapper');
        let orderNew = [];

        for (var i=0, len = currentCols.length; i < len; i++) {
            orderNew.push(parseInt(currentCols[i].getAttribute('data-id')));
        }

        // Order json Obj with the new order after drop
        jsonInput.sort(function(a, b){
            return orderNew.indexOf(a.id) - orderNew.indexOf(b.id)
        });

        ManageProperties.components.editStep3.setImagesToSend(jsonInput);
    }

    return false;
}

function handleDragEnd(e) {
    let cols = document.getElementsByClassName('dashboard-images__image-wrapper');
    for (var i=0, len = cols.length; i < len; i++) {
        cols[i].classList.remove('over');
        cols[i].removeAttribute('style');
    };
}

function handleMobileTouch(e) {
    e.preventDefault();
}

window.attachDragEvents = function() {
    let cols = document.getElementsByClassName('dashboard-images__image-wrapper');
    for (var i=0, len = cols.length; i < len; i++) {
        cols[i].addEventListener('dragstart', handleDragStart, false);
        cols[i].addEventListener('dragenter', handleDragEnter, false);
        cols[i].addEventListener('dragover', handleDragOver, false);
        cols[i].addEventListener('dragleave', handleDragLeave, false);
        cols[i].addEventListener('drop', handleDrop, false);
        cols[i].addEventListener('dragend', handleDragEnd, false);
    };
}
