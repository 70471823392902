Barba.Dispatcher.on("transitionCompleted", function () {

    let forms = document.querySelectorAll('.form:not(.form--custom)');

    Global.components.forms = [];

    forms.forEach(form => {

        Global.components.forms.push(new Vue({
            el: form,
            store,
            mixins: [Mixins.submitForm],
            data: function () {
                return {
                    hasCaptcha: false,
                    captchaExecuted: false,
                    method: 'ideal',
                    confirmation: null

                }
            },
            components: {
                'vue-recaptcha': VueRecaptcha
            },
            computed: {},
            mounted: function () {
            },
            methods: {
                submit: function () {
                    const that = this;
                    this.$validator.validateAll().then(result => {
                        let analytics = that.$el.dataset.analytics;

                        if (result) {
                            if (!this.hasCaptcha || this.captchaExecuted) {
                                that.submitForm(that.$el, analytics);
                            } else {
                                this.$refs.recaptcha.execute();
                            }
                        }
                    });
                },
                onCaptchaVerified: function () {
                    this.captchaExecuted = true;
                    this.submit();
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        }))
    });
});
