Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-edit-step-7')) {
        ManageProperties.components.editStep7 = new Vue({
            el: document.getElementById("property-edit-step-7"),
            store,
            data: function () {
                return {
                    hasGarden: false,
                    hasStorage: false,
                    hasParking: false,
                    initialized: false,
                    imagesGarden: []
                }
            },
            mixins: [Mixins.submitStep, Mixins.propertyJSON, Mixins.uploadImages, Mixins.deleteImage, Mixins.cleanImages, Mixins.addImages],
            computed: {
                imagesGardenToSend() {
                    let clonedImages = JSON.parse(JSON.stringify(this.imagesGarden));

                    clonedImages.forEach((image, imageIndex) => {
                        image['blob'] = null;
                    });

                    return JSON.stringify(clonedImages);
                },
            },
            mounted: function () {
                this.toggleGarden();
                this.toggleStorage();
                this.toggleParking();
                this.setGardenImages();
            },
            watch: {
                imagesGarden: {
                    handler(newValue) {
                        this.$refs.imagesGarden.value = JSON.stringify(newValue);
                    },
                    deep: true
                },
            },
            methods: {
                setGardenImages() {
                    this.imagesGarden = JSON.parse(this.$refs.imagesGarden.value);
                },
                toggleGarden(e) {
                    if (e !== undefined) {
                        this.hasGarden = parseInt(e.target.value);
                    } else {
                        if (document.querySelector('input[name="garden"]:checked')) {
                            this.hasGarden = parseInt(document.querySelector('input[name="garden"]:checked').value);
                        }
                    }
                },
                toggleStorage(e) {
                    if (e !== undefined) {
                        this.hasStorage = parseInt(e.target.value);
                    } else {
                        if (document.querySelector('input[name="storage"]:checked')) {
                            this.hasStorage = parseInt(document.querySelector('input[name="storage"]:checked').value);
                        }
                    }
                },
                toggleParking(e) {
                    if (e !== undefined) {
                        this.hasParking = parseInt(e.target.value);
                    } else {
                        if (document.querySelector('input[name="parking"]:checked')) {
                            this.hasParking = parseInt(document.querySelector('input[name="parking"]:checked').value);
                        }
                    }
                },
                customDeleteImage(images, image, inputID) {
                    this.deleteImage(images, image, inputID);
                    this.setImagesToSend();
                },
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
