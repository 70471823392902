Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("project-map")) {
        Properties.components.projectMap = new Vue({
            el: document.getElementById("project-map"),
            store,
            mixins: [Mixins.objectGoogleMaps],

            mounted: function () {
                let url = '/api/projects';
                let queryString = window.location.href.split('?')[1];
                this.initializeMap();
                this.loadData(url + '?' + queryString);
            },

            methods: {
                loadData(url) {
                    Properties.api.overview(url).then(response => {
                        console.log(response.data);
                        this.addMarkers(response.data);
                    });
                },
            }
        });
    }
});