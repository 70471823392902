Barba.Dispatcher.on("transitionCompleted", function () {

    if (document.querySelectorAll('.property-card:not(.property-card--plain)').length) {
        Properties.components.cards = [];

        document.querySelectorAll('.property-card:not(.property-card--plain)').forEach(propertyCard => {
            Properties.components.cards.push(
                new Vue({
                    el: propertyCard,
                    store,
                    mixins: [Mixins.convertGeoToPixel, Mixins.manageFavorites, Mixins.positionMapIcon],
                    data: function () {
                        return {
                        }
                    },
                    computed: {
                    },
                    methods: {
                        destroy: function (event, message) {
                            console.log('dadadasda');
                            event.preventDefault();

                            if (confirm(message)) {
                                return event.target.submit();
                            }
                        }
                    },
                })
            )
        });
    }
});