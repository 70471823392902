Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-edit-step-1')) {
        ManageProperties.components.editStep1 = new Vue({
            el: document.getElementById("property-edit-step-1"),
            store,
            data: function () {
                return {
                    isProject: 0
                }
            },
            mixins: [Mixins.addressLookup, Mixins.submitStep, Mixins.propertyJSON],
            computed: {
                projectRules() {
                    return this.isProject ? 'required' : '';
                },
            },
            mounted: function () {
                if (this.property.project) {
                    this.isProject = 1;
                }
            },
            methods: {
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});