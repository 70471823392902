Mixins.uploadImages = {
    computed: {
        errorText: {
            get() {
                return this.$store.state.Layout.errorText;
            },
            set(value) {
                this.$store.dispatch('Layout/changeErrorText', value)
            }
        },
        imagesLoading: {
            get() {
                return this.$store.state.Properties.imagesLoading;
            },
            set(value) {
                this.$store.dispatch('Properties/toggleImagesLoading', value)
            }
        }
    },
    methods: {
        uploadImages(e, stored, limit) {
            const that = this;
            return new Promise(function (resolve, reject) {
                let images = [];
                let pushed = 0;

                if ((e.target.files.length + stored) > limit) {
                    e.target.value = null;
                    that.errorText = translations[that.$store.state.Global.currentLanguage]['TooManyImages'].replace('{{ limit }}', limit);
                    reject(translations[that.$store.state.Global.currentLanguage]['notImage']);
                }

                for (let i = 0; i < e.target.files.length; i++) {
                    if (that.isImage(e.target.files[i]) && e.target.files[i].size <= 5000000) {
                        that.storeMedia(e, i).then(function(response) {
                            pushed++;
                            images.push(response);

                            if (pushed === e.target.files.length) {
                                resolve(images);
                            }
                        });
                    } else if (e.target.files[i].size > 5000000) {
                        e.target.value = null;
                        that.errorText = translations[that.$store.state.Global.currentLanguage]['imageTooBig'].replace('{{ limit }}', 5);
                        reject(translations[that.$store.state.Global.currentLanguage]['imageTooBig'].replace('{{ limit }}', 5));
                    } else {
                        e.target.value = null;
                        that.errorText = translations[that.$store.state.Global.currentLanguage]['notImage'];
                        reject(translations[that.$store.state.Global.currentLanguage]['notImage']);
                    }
                }
            });
        },
        isImage(file) {
            return file['type'] === 'image/jpg' || file['type'] === 'image/jpeg';
        },
        storeMedia(e, i) {
            const that = this;

            return new Promise(function (resolve, reject) {
                let formData = new FormData();
                formData.append('image', e.target.files[i]);

                Global.api.storeMedia(formData).then(response => {
                    const reader = new FileReader();

                    reader.onload = (r) => {
                        resolve({
                            path: response.data,
                            blob: r.target.result,
                            name: e.target.files[i].name
                        });
                    };

                    reader.readAsDataURL(e.target.files[i]);
                }).catch(error => {
                    that.imagesLoading = false;
                    that.errorText = error.message;
                });
            });
        }
    }
};