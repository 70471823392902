Properties.store.mutations = {
    addToFavorites(state, value) {
        state.favorites.push(value);
    },
    removeFromFavorites(state, value) {
        const index = state.favorites.indexOf(value);
        state.favorites.splice(index, 1);
    },
    setFavorites(state, value) {
        state.favorites = value;
    },
    setSortOption(state, value) {
        state.sortOption = value;
    },
    setViewMode(state, value) {
        state.viewMode = value;
    },
    setShowBrochureModal(state, value) {
        state.showBrochureModal = value;
    },
    setShowViewingModal(state, value) {
        state.showViewingModal = value;
    },
    setShowOfferModal(state, value) {
        state.showOfferModal = value;
    },
    setImagesLoading(state, value) {
        state.imagesLoading = value;
    },
    setShowFilters(state, value) {
        state.showFilters = value;
    }
};