Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('question-form')) {
        Properties.components.viewingModal = new Vue({
            el: document.getElementById("question-form"),
            store,
            data: function () {
                return {
                    hasCaptcha: false,
                    captchaExecuted: false
                }
            },
            components: {
                'vue-recaptcha': VueRecaptcha
            },
            mixins: [Mixins.submitForm],
            computed: {
            },
            mounted: function () {
            },
            watch: {
            },
            methods: {
                submit: function (e) {
                    const that = this;
                    this.$validator.validateAll().then(result => {
                        if (result) {
                            if (!this.hasCaptcha || this.captchaExecuted) {
                                that.submitForm(this.$el, this.$el.dataset.analytics);
                            } else {
                                this.$refs.recaptcha.execute();
                            }
                        }
                    });
                },
                onCaptchaVerified: function () {
                    this.captchaExecuted = true;
                    this.submit();
                }
            },
        });
    }
});
