Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('compare-slider')) {
        Properties.components.propertySlider = new Vue({
            el: document.getElementById("compare-slider"),
            store,
            mixins: [Mixins.manageFavorites, Mixins.smoothScroll, Mixins.queryString],
            data: function () {
                return {
                    initialized: false,
                    slider: null,
                    nav: null,
                    priceFormatter: new Intl.NumberFormat('nl-NL', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 0
                    }),
                    labels: {},
                    activeTab: 0,
                    properties: []
                }
            },
            watch: {
                activeTab() {
                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'));
                    });

                    let target = this.$el.getElementsByClassName('compare-accordeon__category')[this.activeTab];
                    setTimeout(() => {
                        this.MoveToInstance.move(target);
                    });
                }
            },
            computed: {},
            mounted: function () {
                this.fetchFavoriteProperties();

                Array.prototype.swap = function (x, y) {
                    const b = this[x];
                    this[x] = this[y];
                    this[y] = b;
                    return this;
                }
            },
            methods: {
                findParentBySelector(elm, selector) {
                    const all = document.querySelectorAll(selector);
                    let cur = elm.parentNode;

                    while (cur && !this.collectionHas(all, cur)) { //keep going up until you find a match
                        cur = cur.parentNode; //go up
                    }
                    return cur; //will return null if not found
                },
                collectionHas(a, b) { //helper function (see below)
                    for (let i = 0, len = a.length; i < len; i++) {
                        if (a[i] === b) return true;
                    }
                    return false;
                },
                fetchFavoriteProperties() {
                    const that = this;
                    let url = '/api/favorites';
                    let favorites = [];
                    if (localStorage.getItem('favorites') !== null){
                        favorites = localStorage.getItem('favorites').split(',');
                    }

                    favorites.forEach(favorite => {
                        if (favorite.length) {
                            url = that.addQueryStringParameter(url, 'id[]', favorite)
                        }
                    });

                    if (favorites !== null && favorites.length > 1) {
                        Properties.api.favorites(url).then(response => {
                            that.labels = response.labels;
                            that.properties = response.data;
                            that.initialized = true;

                            that.$nextTick(function () {
                                this.initializeSlider();
                            });
                        });
                    } else {
                        that.initialized = true;
                    }
                },
                initializeSlider() {
                    this.slider = new Flickity(this.$refs.slider, {
                        prevNextButtons: true,
                        pageDots: false,
                        contain: true,
                        groupCells: true,
                        cellAlign: 'left'
                    });

                    let slides = this.$refs.slider.querySelectorAll('.compare-slider__slide');
                    for (let i = 0; i < slides.length; i++) {
                        let slide = slides[i];
                        slide.style.height = "100%";
                    }

                    if (this.$refs.nav) {
                        this.$refs.nav.forEach(nav => {
                            new Flickity(nav, {
                                asNavFor: this.$refs.slider,
                                prevNextButtons: false,
                                pageDots: false,
                                contain: true,
                                groupCells: true,
                                cellAlign: 'left'
                            });
                        });
                    }

                    setTimeout(() => {
                        this.MoveToInstance.move(this.$refs.slider);
                    }, 150);
                },
                removeFromList(id) {
                    this.removeFromFavorites(id);

                    let index = this.properties.findIndex(prop => {
                        return prop.id === id;
                    });

                    this.properties.splice(index, 1);
                    this.slider.resize();

                    //VueJS kan array changes niet scannen, vandaar de force update.
                    this.$forceUpdate();
                },
                moveSlideLeft(num) {
                    this.properties.swap(num, --num);

                    //VueJS kan array changes niet scannen, vandaar de force update.
                    this.$forceUpdate()
                },
                moveSlideRight(num) {
                    this.properties.swap(num, ++num);

                    //VueJS kan array changes niet scannen, vandaar de force update.
                    this.$forceUpdate();
                },
                toggle(index) {
                    if (this.activeTab === index) {
                        this.activeTab = '';
                    } else {
                        this.activeTab = index;
                    }
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});
