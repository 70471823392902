Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('property-edit-step-4')) {
        ManageProperties.components.editStep4 = new Vue({
            el: document.getElementById("property-edit-step-4"),
            store,
            data: function () {
                return {
                }
            },
            mixins: [Mixins.submitStep, Mixins.propertyJSON],
            computed: {
            },
            mounted: function () {
            },
            methods: {
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});