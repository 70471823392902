Barba.Dispatcher.on("transitionCompleted", function () {
    let sliders = document.getElementsByClassName('aside-slider');
    for (let i = 0; i < sliders.length; i++) {
        ManageProperties.components['asideSlider_' + i] = new Vue({
            el: sliders[i],
            store,
            data: function () {
                return {
                    slider: null
                }
            },
            computed: {},
            mounted: function () {
                this.initializeSlider();
            },
            methods: {
                initializeSlider() {
                    this.slider = new Flickity(this.$refs.slider, {
                        adaptiveHeight: true,
                        prevNextButtons: false,
                        contain: true
                    });
                }
            }
        });
    }
});