Global.store.actions = {
    changeMenuExpanded({commit}, value) {
        commit('setMenuExpanded', value)
    },
    toggleMediaVideoModal({commit}, value) {
        commit('setShowMediaVideoModal', value)
    },
    toggleSliderModal({commit}, value) {
        commit('setShowSliderModal', value)
    },
};