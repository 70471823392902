Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("iframe-modal")) {
        Global.components.iframeModal = new Vue({
            el: document.getElementById("iframe-modal"),
            store,
            data() {
                return {
                    src: ''
                }
            },
            mounted() {
            },
            computed: {
                showMediaVideoModal: {
                    get() {
                        return this.$store.state.Global.showMediaVideoModal;
                    },
                    set(value) {
                        this.$store.dispatch('Global/toggleMediaVideoModal', value)
                    }
                }
            },
            watch: {
                showMediaVideoModal(val) {
                    if (val) {
                        this.startVideo();
                    } else {
                        this.stopVideo();
                    }
                }
            },
            methods: {
                stopVideo() {
                    this.src = '';
                },

                startVideo() {
                    this.src = this.$el.dataset.src;
                },
            }
        });
    }
});