Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("property-map")) {
        Properties.components.propertyMap = new Vue({
            el: document.getElementById("property-map"),
            store,
            mixins: [Mixins.objectGoogleMaps],
            mounted: function () {
                let url = '/api/properties';
                let queryString = window.location.href.split('?')[1];
                this.initializeMap();
                this.loadData(url + '?' + queryString, 1);
            },

            methods: {
                loadData(url, page) {
                    Properties.api.overview(url + '&page=' + page).then(response => {
                        this.addMarkers(response.data);

                        // if (response.meta.current_page < response.meta.last_page) {
                        //     this.loadData(url, response.meta.current_page + 1);
                        // }
                    });
                }
            }
        });
    }
});
