Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('viewing-modal')) {
        Properties.components.viewingModal = new Vue({
            el: document.getElementById("viewing-modal"),
            store,
            data: function () {
                return {
                    hasCaptcha: false,
                    captchaExecuted: false,
                    captchaEvent: null,
                }
            },
            components: {
                'vue-recaptcha': VueRecaptcha
            },
            mixins: [Mixins.submitForm],
            computed: {
                showViewingModal: {
                    get() {
                        return this.$store.state.Properties.showViewingModal;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/toggleViewingModal', value)
                    }
                }
            },
            mounted: function () {
            },
            watch: {
            },
            methods: {
                submit: function ($event) {
                    const that = this;
                    let form = $event != undefined ? $event.target : that.$el.querySelector('.form');
                    let analytics = null;

                    if(form.dataset.analytics) {
                        analytics = form.dataset.analytics;
                    }

                    that.$validator.validateAll().then(result => {
                        if (result && analytics) {
                            if (!that.hasCaptcha || that.captchaExecuted) {
                                that.submitForm(form, analytics);
                                if (that.hasCaptcha) {
                                    that.resetCaptcha();
                                }
                            } else {
                                that.$refs.recaptcha.execute();
                            }
                        } else if (result) {
                            that.$root.submitForm(form);
                            if (that.hasCaptcha) {
                                that.resetCaptcha();
                            }
                        }
                    });
                },
                onCaptchaVerified: function () {
                    this.captchaExecuted = true;
                    this.submit();
                },
                resetCaptcha: function() {
                    this.$refs.recaptcha.reset();
                    this.captchaExecuted = false;
                },
            },
        });
    }
});
