Mixins.cleanImages = {
    methods: {
        cleanImages(images) {
            return new Promise(function (resolve) {
                images.forEach((image) => {
                    image['blob'] = null;
                });

                resolve(images);
            });
        },
    }
};