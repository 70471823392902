Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById('offer-modal')) {
        Properties.components.offerModal = new Vue({
            el: document.getElementById("offer-modal"),
            store,
            data: function () {
                return {
                    hasCaptcha: false,
                    captchaExecuted: false,
                    formSlider: null,
                    finalSlide: false,
                    firstSlide: true,
                    priceFormatter:
                        new Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                    }),
                    formValues: {
                        price: null,
                        as: null,
                        company: null,
                        salutation: null,
                        name: null,
                        phone: null,
                        email: null,
                        zipcode: null,
                        housenumber: null,
                        street: null,
                        city: null,
                        country: null,
                        financialCondition: null,
                        technicalInspectionCondition: null,
                        remarks: null,
                    }
                }
            },
            components: {
                'vue-recaptcha': VueRecaptcha
            },
            mixins: [Mixins.submitForm],
            computed: {
                showOfferModal: {
                    get() {
                        return this.$store.state.Properties.showOfferModal;
                    },
                    set(value) {
                        this.$store.dispatch('Properties/toggleOfferModal', value)
                    }
                },
                parsedPrice() {
                    return this.priceFormatter.format(this.formValues.price);
                },
                formChanged() {
                    return this.errors.items.length;
                },
                as() {
                    return this.getOptionText(this.formValues.as, 'as');
                },
                name() {
                    return this.getOptionText(this.formValues.salutation, 'salutation') + ' ' + this.formValues.name;
                },
                country() {
                    return this.getOptionText(this.formValues.country, 'country_id');
                },
                financialCondition() {
                    return this.getOptionText(this.formValues.financialCondition, 'financial-condition');
                },
                technicalInspectionCondition() {
                    return this.getOptionText(this.formValues.technicalInspectionCondition, 'technical-inspection-condition');
                }
            },
            mounted: function () {
                this.initializeSlider();
            },
            watch: {
                showOfferModal() {
                    let resizeEvent = window.document.createEvent('UIEvents');
                    resizeEvent.initUIEvent('resize', true, false, window, 0);
                    window.dispatchEvent(resizeEvent);
                },
                formValues: {
                    handler() {
                        let resizeEvent = window.document.createEvent('UIEvents');
                        resizeEvent.initUIEvent('resize', true, false, window, 0);
                        window.dispatchEvent(resizeEvent);
                    },
                    deep: true
                },
                formChanged: function () {
                    let resizeEvent = window.document.createEvent('UIEvents');
                    resizeEvent.initUIEvent('resize', true, false, window, 0);
                    window.dispatchEvent(resizeEvent);
                }
            },
            methods: {
                initializeSlider() {
                    this.formSlider = new Flickity(this.$refs.offerFormSlider, {
                        adaptiveHeight: true,
                        prevNextButtons: false,
                        pageDots: false,
                        contain: true,
                        draggable: false
                    }).on('change', (index) => {
                        this.$el.getElementsByClassName('modal__content')[0].scrollTop = 0;
                    });
                },
                isFinalSlide() {
                    let index = this.formSlider.selectedIndex;
                    this.finalSlide = this.formSlider.slides.length === ++index;
                },
                isFirstSlide() {
                    this.firstSlide = this.formSlider.selectedIndex === 0;
                },
                // toSlide(i) {
                //     this.formSlider.select(i)
                // },
                nextStep() {
                    this.formSlider.next();
                    this.isFinalSlide();
                    this.isFirstSlide();
                },
                previousStep() {
                    this.formSlider.previous();
                    this.isFinalSlide();
                    this.isFirstSlide();
                },
                isPastSlide(i) {
                    if(this.formSlider !== null) {
                        return this.formSlider.selectedIndex >= i;
                    } else {
                        return false
                    }
                },
                submit: function ($event) {
                    const that = this;
                    let form = $event != undefined ? $event.target : that.$el.querySelector('.form');
                    let analytics = null;

                    if(form.dataset.analytics) {
                        analytics = form.dataset.analytics;
                    }

                    that.$validator.validateAll().then(result => {
                        if (result && analytics) {
                            if (!that.hasCaptcha || that.captchaExecuted) {
                                that.submitForm(form, analytics);
                                if (that.hasCaptcha) {
                                    that.resetCaptcha();
                                }
                            } else {
                                that.$refs.recaptcha.execute();
                            }
                        } else if (result) {
                            that.$root.submitForm(form);
                            if (that.hasCaptcha) {
                                that.resetCaptcha();
                            }
                        }
                    });
                },
                onCaptchaVerified: function () {
                    this.captchaExecuted = true;
                    this.submit();
                },
                resetCaptcha: function() {
                    this.$refs.recaptcha.reset();
                    this.captchaExecuted = false;
                },
                getOptionText: function(value, field) {
                    if (value === null) {
                        return '';
                    }

                    field = this.$el.querySelector('[name="' + field + '"]');

                    let option = field.querySelector('[value="' + value + '"]');
                    if (field.tagName === 'INPUT') {
                        option = document.querySelectorAll('[for="' + field.id + '"]')[1];
                    }

                    return option.innerHTML;
                },
            },
        });
    }
});
