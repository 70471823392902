ManageAccount.store.actions = {
    store({commit}, value) {
        return ManageAccount.api.store(value);
    },
    update({commit}, value) {
        return ManageAccount.api.update(value);
    },
    check({commit}, value) {
        return ManageAccount.api.check(value);
    }
};